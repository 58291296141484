<template>
  <div class="w-full">
    <div class="relative flex _main_wrapper" :class="fullScreen&&'no-left-panel-visible'">
      <div v-if="!fullScreen" class="i-left-panel flex-shrink-0 shadow-one">
        <div class="relative">
          <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">
          <NavToggle v-if="projectId" />
        </div>

        <left-parent
          :assessmentScreen="assessmentScreen"
          :connectionSelection="connectionSelection"
          :isolatedModules="isolatedModules"
          ref="pr_left_sidebar"
          @toggleModuleSelection="toggleModuleSelection"
          @toggleVisualizer="toggleAssessmentScreen"
          @manageListClickCount="manageListClickCount"
          @toggleIsolation="toggleIsolation"
          @updateForceKeys="updateForceKeys"
          @removeIsolation="removeIsolation"
          @toggleModuleIsolation="toggleModuleIsolation"
          @pushToIsolation="pushToIsolation"
          :hasAssessment="hasAssessment"
        />
      </div>
      <div class="relative">
        <div
          style="scroll-behavior: smooth"
          :ref="getRef"
          class="i-right-panel full-height overflow-scroll"
          :class="{'bld-full-w-no-left': fullScreen}"
        >
          <!-- <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo absolute full-screen-logo" v-if="fullScreen"> -->
          <div class="ws-heading-box flex flex-col ">
            <workspace-menu class="z-50 absolute right-3-1" :hideMenu="assessmentScreen" />
            <div class="absolute flex items-center left-10 mt-10 z-50" style="top: 54px;">
              <div class="flex gap-x-5 items-center">
                  <FullscreenBorderedIcon
                      class="w-10 h-10 minimum-shadow"
                      v-if="fullScreen"
                      @click.native="manageListClickCount()"
                      title="Navigation"
                      :active="fullScreen"
                  />
                  <FilterBorderedIcon
                      @click.native="toggleToAutoFilter()"
                      class="w-10 h-10 minimum-shadow"
                      v-if="!assessmentScreen"
                      title="Auto Filter"
                      :active="connectionSelection === 2"
                  />
                  <FunnelBorderedIcon
                      class="w-10 h-10 minimum-shadow"
                      title="Create New Scenario From Filter"
                      @click.native="createScenarioByFilteredCollection"
                      v-if="canCreate(project.cans)"
                  />
                  <DuplicateBorderedIcon
                      class="w-10 h-10 minimum-shadow"
                      title="Copy Scenario (Coming Soon)"
                  />
                  <EditBorderedIcon
                      class="w-10 h-10 minimum-shadow"
                      title="Sketch (Coming Soon)"
                  />
                  <router-link :to="{name: 'scenarioVisualization', params: {projectId: $route.params.id, scenarioId: $route.params.scenario_id}}">
                    <MonitorBorderedIcon
                        class="w-10 h-10 minimum-shadow"
                        title="Quick Visualize"
                    />
                  </router-link>
                  <ResetIcon
                    @click.native="resetZoom"
                    class="w-10 h-10 rounded bg-white border border-primary-four cursor-pointer minimum-shadow"
                    style="padding: 10px"
                  />
              </div>
            </div>
            <!-- style="top: 9px" -->
            <div class="absolute left-10 i-mt-6"
              :class="{
                'full-pr-page-navigations': fullScreen && !assessmentScreen,
                'pr-v-page-navigation': !fullScreen && assessmentScreen,
                'pr-v-full-page-navigation': fullScreen && assessmentScreen,
              }">
              <div class="flex items-center">
                <h2 class="fs-14 font-poppins text-primary-one fw-600">Active:</h2>
                <select class="fs-14 font-poppins text-primary-one fw-600 bg-transparent pr-select-dropdown" v-model="scenario_selected" @change="getSelectionScenarioCollections()">
                    <option
                        v-for="(scenario, scIndex) in scenarios"
                        :key="scIndex"
                        :value="scenario.id"
                        v-text="scenario.title"
                    >
                  </option>
                </select>
              </div>
            </div>
            <div class="absolute pr-visualizer-menus" v-if="assessmentScreen">
              <div class="flex justify-end items-center">
                <h2 class="font-poppins fs-14 fw-700 mr-5 cursor-pointer text-primary-four" v-if="assocChoosedItem.length > 0" @click="$refs.assessment_screen.resetAll()">Reset All</h2>
                <div class="pr-ver-divider bg-primary-four" v-if="assocChoosedItem.length > 0"></div>
                <h2 class="font-poppins fs-14 fw-600 text-primary-four mr-5 cursor-pointer"
                    :class="{'fw-bold': associatedOpt, 'ml-5': assocChoosedItem.length > 0}"
                    @click="$refs.assessment_screen.toggleAssociatedOpt()" v-if="canEdit(project.cans)">Associate</h2>
                <div class="pr-ver-divider bg-primary-four" v-if="canEdit(project.cans)"></div>
                <h2 class="font-poppins fs-14 fw-600 text-primary-four ml-5 cursor-pointer mr-5"
                    :class="{'fw-bold': sortByAssociatedOpt}"
                    @click="$refs.assessment_screen.toggleSortByAssociatedOpt()">Filter by Association</h2>
                <div class="pr-ver-divider bg-primary-four"></div>
                <div class="flex ml-5 fw-bold" @click="toggleAssessmentScreen(false)">
                  <button class="flex justify-center items-center fs-14 fw-bold font-poppins text-primary-four"> Go Back</button>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 75px" class="pr-12"
            :style="[fullScreen ? {'margin-left': '43px'} : {'margin-left': '40px'}]"

          >
            <PanzoomComponent>
              <default-screen
                :selectedModule="selectedModule"
                :filteredCollections="filteredCollections"
                :selectedModuleIndex="selectedModuleIndex"
                :full_screen="fullScreen"
                :isolatedModules="isolatedModules"
                :isolatedModuleId="isolatedModuleId"
                @setDataSourceType="setDataSourceType"
                v-if="!assessmentScreen"
                @toggleAssessmentScreen="toggleAssessmentScreen"
                @toggleConnection="toggleConnection"
                @toggleFullScreen="toggleFullScreen"
                @pushToIsolation="pushToIsolation"
                @activePropertyOption="activePropertyOption"
                ref="default_screen"
              />
              <assessment-screen
                :full_screen="fullScreen"
                :isolatedModules="isolatedModules"
                :isolatedModuleId="isolatedModuleId"
                v-if="assessmentScreen"
                @toggleAssessmentScreen="toggleAssessmentScreen" @toggleFullScreen="toggleFullScreen"
                @resetAll="resetAll"
                @syncAssocChoosedItem="syncAssocChoosedItem"
                @toggleSortByAssociatedOpt="toggleSortByAssociatedOpt"
                @activePropertyOption="activePropertyOption"
                ref="assessment_screen"
              />
            </PanzoomComponent>
          </div>
        </div>
      </div>
    </div>

    <Nav v-model="showNavigation" @close="showNavigation=false"/>
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  // import useZoom from '@/elements/zoom'
  import PanzoomComponent from '@/elements/panzoom/PanzoomComponent'
  import usePanzoom from '@/elements/panzoom/usePanzoom.js'
  import NavToggle from '@/components/navigation/components/NavToggle.vue'
  import useNavigation from '@/components/navigation/useNavigation.js'
  import Nav from '@/components/navigation/Nav.vue'

  const { canDelete, canCreate, canEdit } = usePermission()
  const { resetZoom, getRef } = usePanzoom()
  const { projectId, showNavigation } = useNavigation()
</script>
<script>
import {mapGetters} from 'vuex';
import LeftParent from "@/components/programmatic/leftpart/LeftParent";
import { groupBy } from 'lodash'
import AssessmentScreen from "./AssessmentScreen";
import DefaultScreen from "./DefaultScreen";
import Zoomable from "@/elements/Zoomable"

export default {
  components: {DefaultScreen, AssessmentScreen, LeftParent, Zoomable},
  name: 'Programmatic',
  created() {
    document.title = 'Scenario Canvas';
    const self = this;
    self.$Progress.start();
    self.$store.dispatch("workspace/projectSelection", self.$route.params.id);
    this.$store.dispatch("project/getSolidObject", this.$route.params.id);
    self.$store.dispatch("programmatic/loadScenarios", self.$route.params.id);
    self.$store.dispatch("programmatic/loadScenarioCollection", {
      project_id: self.$route.params.id,
      scenario_id: self.$route.params.scenario_id
    }).then(response => {
      self.$Progress.finish();
      if(this.project && this.project.active_module_id) {
        this.$store.dispatch("programmatic/toggleModuleSelection", this.project.active_module_id);
        let index = this.scenarioModules.findIndex(item => item.id === this.project.active_module_id);
        this.toggleModuleSelection(this.project.active_module_id, index);
      }
    });
    // self.$store.dispatch("programmatic/getModules", self.$route.params.id)
    //     .then(response => {
    //       if (response) {
    //         this.$store.dispatch("programmatic/toggleModuleSelection", self.modules[0].id);
    //         this.toggleModuleSelection(self.modules[0].id, 0);
    //       }
    //     });
  },
  data() {
    return {
        hasAssessment: false,
        filteredCollections: [],
        listClickedCount: 1,
        assessmentScreen: false,
        fullScreen: false,
        connectionSelection: 0,
        autoFilter: 1,
        selectedModule: null,
        selectedModuleIndex: null,
        scenario_selected: this.$route.params.scenario_id,
        assocChoosedItem: [],
        associatedOpt: false,
        sortByAssociatedOpt: false,
        isolatedModules: [],
        isolatedModuleId: null
    }
  },
  computed: {
    ...mapGetters({
      modules: "programmatic/projectModules",
      scenarios: 'programmatic/scenarios',
      project: "project/project",
      scenarioModules: "programmatic/scenarioCollections",
    }),
  },
  methods: {
    setDataSourceType(hasAssessment){
      this.hasAssessment = hasAssessment
    },
    createScenarioByFilteredCollection() {
      if(this.connectionSelection == 2) {
        let collections = groupBy(this.filteredCollections, item => item.module_id);
        let modules = Object.keys(collections).map(moduleId => {
          return {
            id: parseInt(moduleId),
            ids: collections[moduleId].map(i => i.id)
          }
        });

        this.$Progress.start();
        this.$store.dispatch('programmatic/createScenarioByFilteredCollection', {
          scenario_id: parseInt(this.$route.params.scenario_id),
          project_id: parseInt(this.$route.params.id),
          modules
        }).then(response => {
          this.$store.dispatch("programmatic/loadScenarios", this.$route.params.id);
          if(response && response.data && response.data.data && response.data.data.id) {
            this.$Progress.finish();
            if(confirm('New scenario created')) {
              this.filteredCollections = [];
              this.connectionSelection = 1;
              this.listClickedCount = 1;
              this.toggleToAutoFilter();
              this.$router.push({ name: 'programmatic.scenario.view', params: { id: this.$route.params.id, scenario_id: response.data.data.id } });
            }
          }
        })
      } else {
        alert('Please click auto filter first');
      }

    },
    activePropertyOption() {
      this.$refs.pr_left_sidebar.toggleSelectedOption('property');
    },
    toggleAssessmentScreen(value) {
      this.assessmentScreen = value;
      if(this.assessmentScreen) {
        this.autoFilter = 1;
      } else {
        const self = this;
        setTimeout(() => {
          self.$refs.default_screen.setZoomModules();
        }, 200);
      }
    },
    toggleFullScreen() {
      this.fullScreen = !this.fullScreen;
    },
    toggleConnection(value) {
      this.connectionSelection = value;
    },
    toggleToAutoFilter() {
      if(this.autoFilter === 1) {
        this.autoFilter = 2;
      } else {
        this.autoFilter = 1;
      }
      this.$refs.default_screen.toggleConnection(this.autoFilter);
    },
    toggleModuleSelection(id, index) {
      this.selectedModule = id;
      this.selectedModuleIndex = index;
      if (this.assessmentScreen) {
        this.$refs.assessment_screen.scrollToModule(this.selectedModule);
      }
    },
    getSelectionScenarioCollections() {
      let projectId = parseInt(this.$route.params.id);
      let scenarioId = parseInt(this.scenario_selected);
      this.$router.push({ name: 'programmatic.scenario.view', params: { id: projectId, scenario_id: scenarioId }});
      this.$store.dispatch("programmatic/loadScenarioCollection", {
        project_id: projectId,
        scenario_id: scenarioId
      });
    },
    manageListClickCount() {
      if(this.listClickedCount === 2) {
        this.listClickedCount = 1;
      } else {
        this.listClickedCount++;
      }

      if(this.listClickedCount === 1) {
        this.fullScreen = false;
      } else if(this.listClickedCount === 2) {
        this.fullScreen = true;
      }
    },
    resetAll() {
      this.assocChoosedItem = [];
    },
    toggleSortByAssociatedOpt(associatedOpt, sortByAssociatedOpt) {
      this.associatedOpt = associatedOpt;
      this.sortByAssociatedOpt = sortByAssociatedOpt;
    },
    syncAssocChoosedItem(data) {
      this.assocChoosedItem = data;
    },
    pushToIsolation(payload) {
      let module = this.isolatedModules.find(data => data.module_id === parseInt(payload.module_id));
      if(module) {
        if(module.isolatedIds && module.isolatedIds.length > 0 && payload.parent_id) {
          let data = module.isolatedIds.filter(item => item === parseInt(payload.parent_id));
          if(data) {
            module.isolatedIds.push(payload.id);
          }
        }
      }
    },
    toggleIsolation(item) {
      let moduleIndex = this.isolatedModules.findIndex(data => data && data.module_id === item.module_id);
      if(moduleIndex >= 0) {
        let moduleIsolation = this.isolatedModules[moduleIndex];
        if(moduleIsolation.isolatedItemId !== item.id) {
          this.prepareIsolationData(item);
        }
        this.isolatedModules.splice(moduleIndex, 1);
      } else {
        this.prepareIsolationData(item);
      }
    },
    prepareIsolationData(item) {
      let isolateData = item.parents;
      let data = {
        module_id: item.module_id,
        isolate: true,
        isolatedIds: this.getChildIdForIsolation(item, isolateData),
        isolatedItemId: item.id
      }
      this.isolatedModules.push(data);
    },
    getChildIdForIsolation(item, isolateData) {
      isolateData.push(item.id);
      item.child.forEach(child => {
        isolateData = this.getChildIdForIsolation(child, isolateData);
      });
      return isolateData;
    },
    removeIsolation(moduleId) {
      let moduleIndex = this.isolatedModules.findIndex(data => data && data.module_id === moduleId);
      if(moduleIndex >= 0) {
        this.isolatedModules.splice(moduleIndex, 1);
      }
    },
    toggleModuleIsolation(moduleId) {
      if(this.isolatedModuleId === moduleId) {
        this.isolatedModuleId = null;
      } else {
        this.isolatedModuleId = moduleId;
      }
    },
    getIds(item) {
      this.isolatedIds.push(item.id);
      item.child.forEach(child => {
        this.getIds(child);
      });
    },
    updateForceKeys() {
      this.$refs.default_screen.updateForceKeys();
    }
  }
}
</script>

<style scoped>
.pr-ver-divider {
  width: 2px;
  height: 10px;
}
.right-3-1 {
  right: 3.1rem
}
</style>
