<template>
  <div>
    <!--Level 1-->
    <draggable
        :group="{ name: dropGroup, pull: 'clone' }"
        :list="collectionData"
        v-bind="dragOptions"
        :clone="cloneItem"
        @start="startTheDrag($event)"
        @end="dropTheItem($event)">
      <div class="flex flex-col mt-8"
           v-for="(l1, l1Index) in getData(collectionData)"
           :key="l1Index"
           :level="l1.level"
           :did="l1.id">
        <div class="flex flex-row i-h-50" @dblclick="setIsolation([], l1)">
          <div class="relative pr-item flex items-center bld-level-w i-h-50 bg-primary-three rounded cursor-pointer w-full"
               :class="{'i-border-1 border-primary-one' : activeScenarioCollection.id === l1.id}"
               :style="{'background': getBg(l1, 1)}">
            <div class="flex items-center pr-left-content w-full" @click="toggleChildItemSelection(l1.id), setActiveScenarioCollection(l1)">
              <span class="font-poppins fw-600 text-primary-two fs-14 px-4 ellipse prg-lft-title capitalize" :class="{'text-primary-three': selectedChild === l1.id}">{{
                  getIndexTitle(true, 1, l1Index, l1.title)
                }}</span>
            </div>
            <div class="absolute pr-hover-div w-10 i-h-50 cursor-pointer items-center justify-center rounded-br rounded-tr"
                 style="display: flex"
                 @click="toggleChildData(l1)">
              <img  src="/images/icons/chevron_down_black.svg" alt="icon" class=""
                    :class="{'rotate-180': l1.expanded}">
            </div>
          </div>
        </div>

        <!--Level 2-->
        <draggable :group="{ name: dropGroup, pull: 'clone' }"
                   :list="l1.child.data"
                   :clone="cloneItem"
                   v-bind="dragOptions"
                   @start="startTheDrag($event)"
                   @end="dropTheItem($event)">
          <div class="flex flex-col mt-8"
               v-for="(l2, l2Index) in getData(l1.child)"
               :key="l2Index"
               v-show="l1.expanded"
               :level="l2.level"
               :did="l2.id">
            <div class="flex flex-row i-h-50" @dblclick="setIsolation([l1.id], l2)">
              <div class="relative pr-item flex items-center bld-level-w i-h-50 bg-primary-three rounded cursor-pointer w-full"
                   :class="{'i-border-1 border-primary-one' : activeScenarioCollection.id === l2.id}"
                   :style="{'background': getBg(l2, 2)}">
                <div class="flex items-center pr-left-content w-full" @click="toggleChildItemSelection(l2.id), setActiveScenarioCollection(l2)">
                  <span class="font-poppins fw-600 text-primary-two fs-14 px-4 ellipse prg-lft-title capitalize" :class="{'text-primary-three': selectedChild === l2.id}">{{
                      getIndexTitle(false, 2, l2Index, l2.title)
                    }}</span>
                </div>
                <div class="absolute pr-hover-div w-10 i-h-50 cursor-pointer items-center justify-center rounded-br rounded-tr"
                     style="display: flex"
                     @click="toggleChildData(l2)">
                  <img  src="/images/icons/chevron_down_black.svg" alt="icon" class=""
                        :class="{'rotate-180': l2.expanded}">
                </div>
              </div>
            </div>

            <!--Level 3-->
            <draggable :group="{ name: dropGroup, pull: 'clone' }"
                       :list="l2.child.data"
                       :clone="cloneItem"
                       v-bind="dragOptions"
                       @start="startTheDrag($event)"
                       @end="dropTheItem($event)">
              <div class="flex flex-col mt-8"
                   v-for="(l3, l3Index) in getData(l2.child)"
                   :key="l3Index"
                   v-show="l2.expanded"
                   :level="l3.level"
                   :did="l3.id">
                <div class="flex flex-row i-h-50" @dblclick="setIsolation([l1.id, l2.id], l3)">
                  <div class="relative pr-item flex items-center bld-level-w i-h-50 bg-primary-three rounded cursor-pointer w-full"
                       :class="{'i-border-1 border-primary-one' : activeScenarioCollection.id === l3.id}"
                       :style="{'background': getBg(l3, 3)}">
                    <div class="flex items-center pr-left-content w-full" @click="toggleChildItemSelection(l3.id), setActiveScenarioCollection(l3)">
                      <span class="font-poppins fw-600 text-primary-two fs-14 px-4 ellipse prg-lft-title capitalize" :class="{'text-primary-three': selectedChild === l3.id}">{{
                          getIndexTitle(false, 3, l3Index, l3.title)
                        }}</span>
                    </div>
                    <div class="absolute pr-hover-div w-10 i-h-50 cursor-pointer items-center justify-center rounded-br rounded-tr"
                         style="display: flex"
                         @click="toggleChildData(l3)">
                      <img  src="/images/icons/chevron_down_black.svg" alt="icon" class=""
                            :class="{'rotate-180': l3.expanded}">
                    </div>
                  </div>
                </div>

                <!--Level 4-->
                <draggable :group="{ name: dropGroup, pull: 'clone' }"
                           :list="l3.child.data"
                           :clone="cloneItem"
                           v-bind="dragOptions"
                           @start="startTheDrag($event)"
                           @end="dropTheItem($event)">
                  <div class="flex flex-col mt-8"
                       v-for="(l4, l4Index) in getData(l3.child)"
                       :key="l4Index"
                       v-show="l3.expanded"
                       :level="l4.level"
                       :did="l4.id">
                    <div class="flex flex-row i-h-50" @dblclick="setIsolation([l1.id, l2.id, l3.id], l4)">
                      <div class="relative flex items-center bld-level-w i-h-50 bg-primary-three rounded cursor-pointer w-full i-border-1"
                           @click="toggleChildItemSelection(l4.id), setActiveScenarioCollection(l4)"
                           :class="{'i-border-1 border-primary-one' : activeScenarioCollection.id === l4.id}"
                           :style="{'background': getBg(l4, 4)}">
                        <span class="font-poppins fw-600 text-primary-two fs-14 px-4 ellipse prg-lft-title capitalize" :class="{'text-primary-three': selectedChild === l4.id}">{{
                            getIndexTitle(false, 4, l4Index, l4.title)
                          }}</span>
                      </div>
                    </div>
                  </div>
                </draggable>
              </div>
            </draggable>
          </div>
        </draggable>
      </div>
    </draggable>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ScenarioLevelCollection",
  props: ['module', 'collections', 'assessmentScreen', 'connectionSelection', 'isolatedModules'],
  data() {
    return {
      selectedChild: '',
      dropGroup: '',
      groupList: ['identifier', 'area', 'area_measure', 'measure'],
    }
  },
  computed: {
    ...mapGetters({
      colorCodes: "global/colorCodes",
      activeScenarioCollection: "programmatic/activeScenarioCollection",
    }),
    collectionData() {
      if (this.collections && this.collections) {
        return this.collections;
      } else {
        return [];
      }
    },
    getModuleSettings() {
      return JSON.parse(this.module.collection_settings);
    },
    dragOptions() {
      return {
        animation: 300,
        disabled: true,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    toggleChildItemSelection(id) {
      this.selectedChild = id;
    },
    toggleChildData(item) {
      item.expanded = !item.expanded;
    },
    getBgColor(level) {
      let collectionSettings = this.getModuleSettings;
      if (collectionSettings && collectionSettings.collections.length > 0) {
        return collectionSettings.collections[level].color;
      }
    },
    getIndexTitle(root, parentCodeIndex, index, title) {
      let nextNumber = index + 1;
      let returnTitle = '';
      let moduleSettings = this.getModuleSettings;
      if (moduleSettings && moduleSettings.collections) {
        if (moduleSettings.index_id) {
          returnTitle = moduleSettings.collections[parentCodeIndex].index_id;
        }
        if (moduleSettings.index_number) {
          if (root) {
            let number = parseInt(moduleSettings.collections[parentCodeIndex].index_number) + parseInt(index);
            returnTitle = returnTitle + number;
          } else {
            returnTitle = returnTitle + moduleSettings.collections[parentCodeIndex - 1].index_number + '.' + nextNumber;
          }
        }
        return returnTitle + ' ' + title;
      } else {
        return title;
      }
    },
    cloneItem() {
      return;
    },
    startTheDrag(e) {
      let level = e.item.getAttribute('level');
      this.dropGroup = this.groupList[level - 1];
    },
    dropTheItem(e) {
      const self = this;
      let level = e.item.getAttribute('level');
      let copiedId = e.item.getAttribute('did');
      let parentId = null;
      if (e.to.getAttribute("identifier-id")) {
        parentId = e.to.getAttribute("identifier-id");
      } else if (e.to.getAttribute("area-id")) {
        parentId = e.to.getAttribute("area-id");
      } else if (e.to.getAttribute("area-measure-id")) {
        parentId = e.to.getAttribute("area-measure-id");
      }
      if(parentId || e.to.getAttribute("module-id") || e.to.getAttribute("id")) {
        let moduleId = e.to.getAttribute("module-id") ? e.to.getAttribute("module-id") : e.to.getAttribute("id");
        let data = {
          copied_id: copiedId,
          index: e.newIndex,
          module_id: moduleId,
          level: level,
          parent_id: parentId,
          scenario_id: self.$route.params.scenario_id,
          project_id: self.$route.params.id,
          global: false
        };
        self.$store.dispatch("programmatic/storeDataFromLocalList", data);
        self.dropGroup = '';
      }
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    getBg(item, level) {
      if(item.background_color) {
        return item.background_color;
      } else {
        let collectionSettings = this.getModuleSettings;
        if(collectionSettings && collectionSettings.collections.length > 0) {
          if(collectionSettings.collections[level].custom_bg) {
            return collectionSettings.collections[level].custom_bg;
          }
          let colorCodes = this.getLevelWiseColorCode(level);
          return colorCodes[collectionSettings.collections[level].color];
        }
        return null;
      }
    },
    setIsolation(parents, item) {
      item.parents = parents;
      this.$emit("toggleIsolation", item);
    },
    getData(items) {
      let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.module.id);
      if(moduleInIsolation && moduleInIsolation.isolate) {
        return items.filter(data => moduleInIsolation.isolatedIds.includes(data.id));
      }
      return items;
    },
    setActiveScenarioCollection(item) {
      let data = {}
      data.data = item
      this.$emit("activePropertyOption");
      let hasChild = item.child && item.child && item.child.length > 0;
      this.$store.dispatch('programmatic/propertyChangeable', !hasChild);
      this.$store.commit("programmatic/setActiveScenarioCollection", data)
      this.$store.dispatch("association/getItemAssociations", item.id);
    },
  },
}
</script>

<style scoped>

</style>
