<template>
  <div class="relative" :style="{'display': parentModule.expandedLevel > 0 ? 'block' : 'none'}" :class="{'mr-10': parentModule.expandedLevel > 0}" @click="closeMenuHandler($event)" >
    <div class="flex panzoom-exclude">
      <input type="text "
        class="font-poppins fs-14 text-secondary-one fw-700 uppercase bg-transparent w-244"
        v-if="viewMode != 3 || viewMode == 3 && parentModule.expandedLevel > 3"
        v-model="parentModule.title"
        :disabled="!canEdit(project.cans)"
        @change="updateModuleTitle()" />
      <div class="flex ml-4" v-if="viewMode === 1 && parentModule.expandedLevel >= 3" :class="viewMode == 3 && parentModule.expandedLevel == 1 ? 'justify-center pl-5' : 'justify-between'">
        <div class="flex items-center">
          <div class="panzoom-exclude flex items-center">
            <h3 class="font-poppins fs-14 text-secondary-two">P.Set:</h3>
            <select
              class="fs-14 font-poppins bg-transparent text-secondary-two pr-select-dropdown"
              v-model="propertySelected"
              @change="changeChildItem()">
              <option
                  v-for="(property, index) in properties"
                  :key="index"
                  :value="property.id">
                {{ property.title }}
              </option>
            </select>
          </div>
          <div class="panzoom-exclude flex items-center ml-5">
            <h3 class="font-poppins fs-14 text-secondary-two">Parameter:</h3>
            <select
              class="fs-14 font-poppins bg-transparent text-secondary-two pr-select-dropdown"
              v-model="propertyChildSelected"
              @change="changeChildProp">
              <option
                  v-for="(propChild, cIndex) in childProperties"
                  :key="cIndex"
                  :value="propChild.id">
                {{ propChild.title }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-between prog-slider-h mt-5" v-if="viewMode == 1">
      <div class="pr-wide-bar-range" v-if="parentModule.expandedLevel > 0">
        <dual-range-slider
          class="rounded w-full cursor-pointer"
          :id="'slider-'+module_id+'-l1'"
          :min="l1min"
          :max="l1max"
          @update:min="value => l1min = value"
          @update:max="value => l1max = value"
          @resetMode="$emit('resetConn')">
        </dual-range-slider>
      </div>
      <div class="pr-wide-bar-range i-ml-30" v-if="parentModule.expandedLevel > 1">
        <dual-range-slider class="rounded w-full cursor-pointer" :id="'slider-'+module_id+'-l2'" :min="l2min" :max="l2max" @update:min="value => l2min = value" @update:max="value => l2max = value" @resetMode="$emit('resetConn')"></dual-range-slider>
      </div>
      <div class="pr-wide-bar-range i-ml-30" v-if="parentModule.expandedLevel > 2">
        <dual-range-slider class="rounded w-full cursor-pointer" :id="'slider-'+module_id+'-l3'" :min="l3min" :max="l3max" @update:min="value => l3min = value" @update:max="value => l3max = value" @resetMode="$emit('resetConn')"></dual-range-slider>
      </div>
      <div class="pr-wide-bar-range i-ml-30" v-if="parentModule.expandedLevel > 3">
        <dual-range-slider class="rounded w-full cursor-pointer" :id="'slider-'+module_id+'-l4'" :min="l4min" :max="l4max" @update:min="value => l4min = value" @update:max="value => l4max = value" @resetMode="$emit('resetConn')"></dual-range-slider>
      </div>
    </div>

    <div class="prog-child-content-div overflow-y-auto" v-if="selectedConnection > 0"
         :canvas-id="module_id"
         :class="{'pr-no-property': parentModule.expandedLevel <= 3, 'pr-view-mode-1': viewMode != 1, 'pr-view-mode-2': viewMode == 3 && parentModule.expandedLevel <= 3}">
        <draggable
          :disabled="!canEdit(project.cans)"
          group="identifier"
          class="prog-l1-div"
          :id="module_id"
          @end="dropL1Item($event)">
        <transition-group name="flip-list" key="root" :module-id="module_id" level="1" canvas-content="true" class="prog-min-expanded" v-if="getData(getModuleCollectionData).length > 0" :class="{'prog-min-minimized' : viewMode == 3}">
          <div v-for="(l1, l1Index) in getData(getModuleCollectionData)" v-if="checkLevel1Filter(l1)" class="item-top-mt" :key="l1Index" :item-level="l1.level" level-type="identifier" parent-level-type="root" :item-id="l1.id">
            <div class="flex" :key="l1.id" v-if="parentModule.expandedLevel > 0">
              <div class="prog-lc-item panzoom-exclude rounded flex relative items-baseline border-solid shadow-two border-color-transparent cursor-pointer"
                  :style="{ 'background': appearance == 0 ? getBg(1, l1) : 'transparent' }"
                  @click="setActiveScenarioCollection(l1)"
                  :title="viewMode != 1 ? getIndexTitle(true, 1, l1Index) + ' ' + l1.title : ''"
                  v-on:blur="contextmenu = false"
                  :class="{
                    'i-border-1 no-shadow border-primary-one': appearance == 1,
                    'i-border-b-1 no-shadow border-primary-one': appearance == 2,
                    'flex-col pb-2': description,
                    'i-border-1 border-primary-one active' : activeScenarioCollection.id === l1.id,
                    'border-secondary-five': activeScenarioCollection.id !== l1.id && (appearance == 1 || appearance == 2),
                    'no-radius': appearance == 2 && activeScenarioCollection.id !== l1.id,
                    'rounded i-border-1' : appearance != 2,
                    'prog-mode-1' : viewMode == 1 || parentModule.expandedLevel == 1,
                    'prog-mode-2': (viewMode == 2 && parentModule.expandedLevel != 1) || viewMode == 3
                  }
              ">
                <div class="action_items">
                  <button title="Information" @click="menuHandler($event, l1)">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333Z" fill="#8D8D8D"/>
                      <path d="M6.50431 5.072C6.26431 5.072 6.06698 5.00267 5.91231 4.864C5.76298 4.72 5.68831 4.544 5.68831 4.336C5.68831 4.12267 5.76298 3.94667 5.91231 3.808C6.06698 3.664 6.26431 3.592 6.50431 3.592C6.73898 3.592 6.93098 3.664 7.08031 3.808C7.23498 3.94667 7.31231 4.12267 7.31231 4.336C7.31231 4.544 7.23498 4.72 7.08031 4.864C6.93098 5.00267 6.73898 5.072 6.50431 5.072ZM7.18431 5.536V10H5.81631V5.536H7.18431Z" fill="#8D8D8D"/>
                    </svg>
                  </button>
                  <button title="Create" @click="()=>{canCreate(project.cans)&&createNewItem(l1)}">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333ZM3.33398 7.33333L3.33398 6L6.00065 6L6.00065 3.33333L7.33398 3.33333L7.33398 6L10.0007 6L10.0007 7.33333L7.33398 7.33333L7.33398 10L6.00065 10L6.00065 7.33333L3.33398 7.33333Z" fill="#8D8D8D"/>
                    </svg>
                  </button>
                  <button title="Delete" @click="canDelete(project.cans) && deleteLevelData(l1.id)">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.000650114 6.66691C0.000650275 2.98501 2.98542 0.000243688 6.66732 0.000243849C10.3492 0.00024401 13.334 2.98501 13.334 6.66691C13.334 10.3488 10.3492 13.3336 6.66732 13.3336C2.98709 13.3295 0.00469163 10.3471 0.000650114 6.66691ZM6.55265 1.33358C3.61849 1.36512 1.26095 3.76098 1.27665 6.69527C1.2925 9.62956 3.67565 11.9999 6.60999 11.9999C9.54431 11.9999 11.9275 9.62956 11.9433 6.69527C11.959 3.76098 9.60148 1.36512 6.66732 1.33358L6.55265 1.33358ZM3.33398 7.33358L3.33398 6.00024L6.00065 6.00024L7.33398 6.00024L10.0007 6.00024L10.0007 7.33358L7.33398 7.33358L6.00065 7.33358L3.33398 7.33358Z" fill="#8D8D8D"/>
                    </svg>
                  </button>
                </div>

                <div class="flex items-center justify-center prog-lc-width i-h-50 i-pl-15 i-pr-15" :class="{'i-h-description': description}">
                  <h2 class="text-primary-three font-poppins fs-13 fw-600 mr-1" :class="{'text-secondary-one': appearance == 1 || appearance == 2}"
                      v-if="indexTitle && (viewMode == 1 || l1.child.length > 1 && parentModule.expandedLevel != 1 || l1.child.length > 1 && viewMode == 2)">
                    {{ getIndexTitle(true, 1, l1Index) }}</h2>
                  <h2 class="text-primary-three font-poppins fs-13 fw-600"
                      :class="{'text-secondary-one': appearance == 1 || appearance == 2, 'mr-1': indexTitle}"
                      v-else>
                    {{ getIndexShortTitle(true, 1, l1Index) }}
                  </h2>
                  <input type="text panzoom-exclude"
                    :disabled="!canEdit(project.cans)"
                    @change="regularUpdate(l1)"
                    v-model="l1.title"
                    v-show="!((viewMode == 2 && parentModule.expandedLevel != 1) || viewMode == 3)"
                    class="block text-primary-three w-full fw-600 focus:outline-none bg-transparent font-poppins fs-13 py-3 placeholder-secondary-two cursor-pointer"
                      autocomplete="off"
                      :class="{'text-secondary-one': appearance == 1 || appearance == 2}">
                </div>
                <textarea
                  :disabled="!canEdit(project.cans)"
                  class="fs-12 font-poppins text-primary-three selected-a-tiles-bg-indt build-item-color i-pl-15 i-pr-15 h-full w-full bg-transparent pr-content-area cursor-pointer"
                  style="margin-top: -12px;"
                  placeholder="Add descriptive text"
                  @change="regularUpdate(l1)"
                  v-model="l1.description"
                  v-show="description && viewMode == 1"
                  :class="{'text-secondary-one': appearance == 1 || appearance == 2}"
                  autocomplete="off"
                ></textarea>
                <h4 class="text-secondary-one font-poppins absolute fs-13 pr-prop-value-pos" v-if="viewMode == 1 || parentModule.expandedLevel == 1 && viewMode != 3">{{ getProperty(l1) }}</h4>
              </div>
              <draggable
                :disabled="!canEdit(project.cans)"
                :group="{ name: 'area' }"
                class="flex flex-col i-ml-30"
                v-if="parentModule.expandedLevel > 1"
                :identifier-id="l1.id"
                @end="dropL2Item($event)">
                <transition-group name="flip-list" :identifier-id="l1.id" :key="`l1Index-${l1Index}`" :module-id="l1.module_id" level="2" canvas-content="true" class="prog-min-expanded" :class="{'prog-min-minimized' : viewMode == 3}">
                  <div v-for="(l2, l2Index) in getData(l1.child)" v-if="checkLevel2Filter(l2)" class="item-top-mt" :key="`l2Index-${l2Index}`" :item-level="l2.level" level-type="area" parent-level-type="identifier" :item-id="l2.id">
                    <div :key="l2.id">
                      <div class="flex">
                        <div class="panzoom-exclude prog-lc-item flex items-baseline relative rounded border-solid shadow-two border-color-transparent cursor-pointer"
                          :style="{ 'background': appearance == 0 ? getBg(2, l2) : 'transparent' }"
                          @click="setActiveScenarioCollection(l2)"
                          :title="viewMode != 1 ? getIndexTitle(false, 2, l2Index, l1Index) + ' ' + l2.title : ''"
                          :class="{
                            'i-border-1 no-shadow border-primary-one': appearance == 1,
                            'i-border-b-1 no-shadow border-primary-one': appearance == 2,
                            'flex-col': description,
                            'i-border-1 border-primary-one active' : activeScenarioCollection.id === l2.id,
                            'border-secondary-five': activeScenarioCollection.id !== l2.id && (appearance == 1 || appearance == 2),
                            'no-radius': appearance == 2 && activeScenarioCollection.id !== l2.id,
                            'flex-col pb-2': description,
                            'prog-mode-1' : viewMode == 1 || parentModule.expandedLevel == 2,
                            'rounded i-border-1' : appearance != 2,
                            'prog-mode-2': (viewMode == 2 && parentModule.expandedLevel != 2) || viewMode == 3
                          }"
                        >
                          <div class="action_items">
                            <button title="Information" @click="menuHandler($event, l2)">
                              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333Z" fill="#8D8D8D"/>
                                <path d="M6.50431 5.072C6.26431 5.072 6.06698 5.00267 5.91231 4.864C5.76298 4.72 5.68831 4.544 5.68831 4.336C5.68831 4.12267 5.76298 3.94667 5.91231 3.808C6.06698 3.664 6.26431 3.592 6.50431 3.592C6.73898 3.592 6.93098 3.664 7.08031 3.808C7.23498 3.94667 7.31231 4.12267 7.31231 4.336C7.31231 4.544 7.23498 4.72 7.08031 4.864C6.93098 5.00267 6.73898 5.072 6.50431 5.072ZM7.18431 5.536V10H5.81631V5.536H7.18431Z" fill="#8D8D8D"/>
                              </svg>
                            </button>
                            <button title="Create" @click="()=>{canCreate(project.cans) && canCreate(project.cans)&&createNewItem(l2)}">
                              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333ZM3.33398 7.33333L3.33398 6L6.00065 6L6.00065 3.33333L7.33398 3.33333L7.33398 6L10.0007 6L10.0007 7.33333L7.33398 7.33333L7.33398 10L6.00065 10L6.00065 7.33333L3.33398 7.33333Z" fill="#8D8D8D"/>
                              </svg>
                            </button>
                            <button title="Delete" @click="canDelete(project.cans) && deleteLevelData(l2.id)">
                              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.000650114 6.66691C0.000650275 2.98501 2.98542 0.000243688 6.66732 0.000243849C10.3492 0.00024401 13.334 2.98501 13.334 6.66691C13.334 10.3488 10.3492 13.3336 6.66732 13.3336C2.98709 13.3295 0.00469163 10.3471 0.000650114 6.66691ZM6.55265 1.33358C3.61849 1.36512 1.26095 3.76098 1.27665 6.69527C1.2925 9.62956 3.67565 11.9999 6.60999 11.9999C9.54431 11.9999 11.9275 9.62956 11.9433 6.69527C11.959 3.76098 9.60148 1.36512 6.66732 1.33358L6.55265 1.33358ZM3.33398 7.33358L3.33398 6.00024L6.00065 6.00024L7.33398 6.00024L10.0007 6.00024L10.0007 7.33358L7.33398 7.33358L6.00065 7.33358L3.33398 7.33358Z" fill="#8D8D8D"/>
                              </svg>
                            </button>
                          </div>
                          <div class="flex items-center justify-center prog-lc-width i-h-50 i-pl-15 i-pr-15" :class="{'i-h-description': description}">
                            <h2 class="text-primary-three font-poppins fs-13 fw-600 mr-1 hello" :class="{'text-secondary-one': appearance == 1 || appearance == 2}"
                                v-if="indexTitle && (viewMode == 1 || parentModule.expandedLevel == 2 && viewMode != 3 || l2.child.length > 1 && parentModule.expandedLevel != 2 && viewMode != 1)">
                              {{ getIndexTitle(false, 2, l2Index, l1Index) }}</h2>
                            <h2 class="text-primary-three font-poppins fs-13 fw-600" :class="{'text-secondary-one': appearance == 1 || appearance == 2, 'mr-1': indexTitle}" v-else>
                              {{ getIndexShortTitle(false, 2, l2Index) }}</h2>
                            <input type="text"
                              :disabled="!canEdit(project.cans)"
                              @change="regularUpdate(l2)"
                              v-model="l2.title"
                              v-show="!((viewMode == 2 && parentModule.expandedLevel != 2) || viewMode == 3)"
                              class="block text-primary-three w-full fw-600 focus:outline-none bg-transparent font-poppins fs-13 py-3 placeholder-secondary-two cursor-pointer"
                              autocomplete="off"
                              :class="{'text-secondary-one': appearance == 1 || appearance == 2}">
                          </div>
                          <textarea
                            :disabled="!canEdit(project.cans)"
                            class="fs-12 font-poppins text-primary-three selected-a-tiles-bg-indt build-item-color i-pl-15 i-pr-15 h-full w-full bg-transparent pr-content-area cursor-pointer"
                            style=" margin-top: -12px;"
                            placeholder="Add descriptive text"
                            @change="regularUpdate(l2)"
                            v-model="l2.description"
                            v-show="description && viewMode == 1"
                            :class="{'text-secondary-one': appearance == 1 || appearance == 2}"
                            autocomplete="off"
                          ></textarea>
                          <h4 class="text-secondary-one font-poppins absolute fs-12 pr-prop-value-pos" v-if="viewMode == 1 || parentModule.expandedLevel == 2 && viewMode != 3">{{ getProperty(l2) }}</h4>
                        </div>
                        <draggable
                          :disabled="!canEdit(project.cans)"
                          group="area_measure"
                          class="flex flex-col i-ml-30"
                          v-if="parentModule.expandedLevel > 2"
                          @end="dropL3Item($event)">
                          <transition-group :key="`l2Index-${l2Index}`" :area-id="l2.id" :module-id="l2.module_id" level="3" canvas-content="true" class="prog-min-expanded" :class="{'prog-min-minimized' : viewMode == 3}">
                            <div v-for="(l3, l3Index) in getData(l2.child)" v-if="checkLevel3Filter(l3)" class="item-top-mt" :key="`l3Index-${l3Index}`" :item-level="l3.level" level-type="area-measure" parent-level-type="area" :item-id="l3.id" >
                              <div :key="l3.id" >
                                <div class="flex">
                                  <div class="prog-lc-item panzoom-exclude flex items-baseline relative rounded border-solid shadow-two border-color-transparent cursor-pointer"
                                      :style="{
                                        'background': appearance == 0 ? getBg(3, l3) : 'transparent',
                                      }"
                                      @click="setActiveScenarioCollection(l3)"
                                      :title="viewMode != 1 ? getIndexTitle(false, 3, l3Index, l1Index, l2Index) + ' ' + l3.title : ''"
                                      :class="{
                                        'i-border-1 no-shadow border-primary-one': appearance == 1,
                                        'i-border-b-1 no-shadow border-primary-one': appearance == 2,
                                        'flex-col pb-2': description,
                                        'i-border-1 border-primary-one active' : activeScenarioCollection.id === l3.id,
                                        'border-secondary-five': activeScenarioCollection.id !== l3.id && (appearance == 1 || appearance == 2),
                                        'no-radius': appearance == 2 && activeScenarioCollection.id !== l3.id,
                                        'prog-mode-1' : viewMode == 1 || parentModule.expandedLevel == 3,
                                        'flex-col': description,
                                        'rounded i-border-1' : appearance != 2,
                                        'prog-mode-2': (viewMode == 2 && parentModule.expandedLevel != 3) || viewMode == 3
                                      }"
                                    >
                                      <div class="action_items">
                                        <button title="Information" @click="menuHandler($event, l3)">
                                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333Z" fill="#8D8D8D"/>
                                            <path d="M6.50431 5.072C6.26431 5.072 6.06698 5.00267 5.91231 4.864C5.76298 4.72 5.68831 4.544 5.68831 4.336C5.68831 4.12267 5.76298 3.94667 5.91231 3.808C6.06698 3.664 6.26431 3.592 6.50431 3.592C6.73898 3.592 6.93098 3.664 7.08031 3.808C7.23498 3.94667 7.31231 4.12267 7.31231 4.336C7.31231 4.544 7.23498 4.72 7.08031 4.864C6.93098 5.00267 6.73898 5.072 6.50431 5.072ZM7.18431 5.536V10H5.81631V5.536H7.18431Z" fill="#8D8D8D"/>
                                          </svg>
                                        </button>
                                        <button title="Create" @click="()=>{canCreate(project.cans)&&createNewItem(l3)}">
                                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333ZM3.33398 7.33333L3.33398 6L6.00065 6L6.00065 3.33333L7.33398 3.33333L7.33398 6L10.0007 6L10.0007 7.33333L7.33398 7.33333L7.33398 10L6.00065 10L6.00065 7.33333L3.33398 7.33333Z" fill="#8D8D8D"/>
                                          </svg>
                                        </button>
                                        <button title="Delete" @click="canDelete(project.cans) && deleteLevelData(l3.id)">
                                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.000650114 6.66691C0.000650275 2.98501 2.98542 0.000243688 6.66732 0.000243849C10.3492 0.00024401 13.334 2.98501 13.334 6.66691C13.334 10.3488 10.3492 13.3336 6.66732 13.3336C2.98709 13.3295 0.00469163 10.3471 0.000650114 6.66691ZM6.55265 1.33358C3.61849 1.36512 1.26095 3.76098 1.27665 6.69527C1.2925 9.62956 3.67565 11.9999 6.60999 11.9999C9.54431 11.9999 11.9275 9.62956 11.9433 6.69527C11.959 3.76098 9.60148 1.36512 6.66732 1.33358L6.55265 1.33358ZM3.33398 7.33358L3.33398 6.00024L6.00065 6.00024L7.33398 6.00024L10.0007 6.00024L10.0007 7.33358L7.33398 7.33358L6.00065 7.33358L3.33398 7.33358Z" fill="#8D8D8D"/>
                                          </svg>
                                        </button>
                                      </div>

                                    <div class="flex items-center justify-center prog-lc-width i-h-50 i-pl-15 i-pr-15" :class="{'i-h-description': description}">
                                      <h2 class="text-primary-three font-poppins fs-13 fw-600" :class="{'text-secondary-one': appearance == 1 || appearance == 2}"
                                          v-if="indexTitle && (viewMode == 1 || viewMode == 2 && (l3.child.length > 1 || parentModule.expandedLevel == 3) || viewMode == 3 && parentModule.expandedLevel == 4 && l3.child.length > 1)">
                                        {{ getIndexTitle(false, 3, l3Index, l1Index, l2Index) }}</h2>
                                      <h2 class="text-primary-three font-poppins fs-13 fw-600"
                                          :class="{'text-secondary-one': appearance == 1 || appearance == 2, 'mr-1': indexTitle}"
                                          v-else>
                                        {{ getIndexShortTitle(false, 3, l3Index) }}</h2>
                                      <input type="text"
                                        :disabled="!canEdit(project.cans)"
                                        @change="regularUpdate(l3)"
                                        v-model="l3.title"
                                        v-show="!((viewMode == 2 && parentModule.expandedLevel != 3) || viewMode == 3)"
                                        class="block text-primary-three w-full fw-600 focus:outline-none bg-transparent font-poppins fs-13 py-3 i-h-50 placeholder-secondary-two cursor-pointer"
                                        autocomplete="off"
                                        :class="{'text-secondary-one': appearance == 1 || appearance == 2}">
                                    </div>
                                    <textarea
                                      :disabled="!canEdit(project.cans)"
                                      class="fs-12 font-poppins text-primary-three selected-a-tiles-bg-indt build-item-color i-pl-15 i-pr-15 h-full w-full bg-transparent pr-content-area cursor-pointer"
                                      style=" margin-top: -12px;"
                                      placeholder="Add descriptive text"
                                      @change="regularUpdate(l3)"
                                      v-model="l3.description"
                                      v-show="description && viewMode == 1"
                                      :class="{'text-secondary-one': appearance == 1 || appearance == 2}"
                                      autocomplete="off"
                                    ></textarea>
                                    <h4 class="text-secondary-one font-poppins absolute fs-12 pr-prop-value-pos" v-if="viewMode == 1 || parentModule.expandedLevel == 3 && viewMode != 3">{{ getProperty(l3) }}</h4>
                                  </div>
                                  <draggable
                                      group="measure"
                                      class="flex flex-col i-ml-30"
                                      v-if="parentModule.expandedLevel > 3"
                                      @end="dropL4Item($event)">
                                    <transition-group :key="`l3Index-${l3Index}`" :area-measure-id="l3.id" :module-id="l3.module_id" level="4" canvas-content="true" class="prog-min-expanded" :class="{'prog-min-minimized' : viewMode == 3}">
                                      <div v-for="(l4, l4Index) in getData(l3.child)" v-if="checkLevel4Filter(l4)" class="item-top-mt" :key="l4Index" :item-level="l4.level" level-type="measure" parent-level-type="area-measure" :item-id="l4.id" >
                                        <div class="flex" :key="l4.id">
                                          <div class="prog-lc-item panzoom-exclude flex items-baseline relative rounded border-solid shadow-two border-color-transparent cursor-pointer"
                                              :style="{
                                                'background': appearance == 0 ? getBg(4, l4) : 'transparent',
                                              }"
                                              @click="setActiveScenarioCollection(l4)"
                                              :title="viewMode != 1 ? getIndexTitle(false, 4, l4Index, l1Index, l2Index, l3Index) + ' ' + l4.title : ''"
                                              :class="{
                                                'i-border-1 no-shadow border-primary-one': appearance == 1,
                                                'i-border-b-1 no-shadow border-primary-one': appearance == 2,
                                                'flex-col pb-2': description,
                                                'i-border-1 border-primary-one rounded active' : activeScenarioCollection.id === l4.id,
                                                'border-secondary-five': activeScenarioCollection.id !== l4.id && (appearance == 1 || appearance == 2),
                                                'no-radius': appearance == 2 && activeScenarioCollection.id !== l4.id,
                                                'prog-mode-1' : viewMode == 1 || viewMode == 2,
                                                'flex-col': description,
                                                'rounded i-border-1' : appearance != 2,
                                                'prog-mode-2': viewMode == 3
                                              }"
                                            >
                                              <div class="action_items">
                                                <button title="Information" @click="menuHandler($event, l4)">
                                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333Z" fill="#8D8D8D"/>
                                                    <path d="M6.50431 5.072C6.26431 5.072 6.06698 5.00267 5.91231 4.864C5.76298 4.72 5.68831 4.544 5.68831 4.336C5.68831 4.12267 5.76298 3.94667 5.91231 3.808C6.06698 3.664 6.26431 3.592 6.50431 3.592C6.73898 3.592 6.93098 3.664 7.08031 3.808C7.23498 3.94667 7.31231 4.12267 7.31231 4.336C7.31231 4.544 7.23498 4.72 7.08031 4.864C6.93098 5.00267 6.73898 5.072 6.50431 5.072ZM7.18431 5.536V10H5.81631V5.536H7.18431Z" fill="#8D8D8D"/>
                                                  </svg>
                                                </button>
                                                <button title="Create" @click="()=>{canCreate(project.cans)&&createNewItem(l4)}">
                                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333ZM3.33398 7.33333L3.33398 6L6.00065 6L6.00065 3.33333L7.33398 3.33333L7.33398 6L10.0007 6L10.0007 7.33333L7.33398 7.33333L7.33398 10L6.00065 10L6.00065 7.33333L3.33398 7.33333Z" fill="#8D8D8D"/>
                                                  </svg>
                                                </button>
                                                <button title="Delete" @click="canDelete(project.cans) && deleteLevelData(l4.id)">
                                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.000650114 6.66691C0.000650275 2.98501 2.98542 0.000243688 6.66732 0.000243849C10.3492 0.00024401 13.334 2.98501 13.334 6.66691C13.334 10.3488 10.3492 13.3336 6.66732 13.3336C2.98709 13.3295 0.00469163 10.3471 0.000650114 6.66691ZM6.55265 1.33358C3.61849 1.36512 1.26095 3.76098 1.27665 6.69527C1.2925 9.62956 3.67565 11.9999 6.60999 11.9999C9.54431 11.9999 11.9275 9.62956 11.9433 6.69527C11.959 3.76098 9.60148 1.36512 6.66732 1.33358L6.55265 1.33358ZM3.33398 7.33358L3.33398 6.00024L6.00065 6.00024L7.33398 6.00024L10.0007 6.00024L10.0007 7.33358L7.33398 7.33358L6.00065 7.33358L3.33398 7.33358Z" fill="#8D8D8D"/>
                                                  </svg>
                                                </button>
                                              </div>
                                            <div class="flex items-center justify-center prog-lc-width i-h-50 i-pl-15 i-pr-15" :class="{'i-h-description': description}">
                                              <h2 class="text-primary-three font-poppins fs-13 fw-600 mr-1" :class="{'text-secondary-one': appearance == 1 || appearance == 2}"
                                                  v-if="indexTitle && (viewMode == 1 || viewMode == 2)">
                                              {{ getIndexTitle(false, 4, l4Index, l1Index, l2Index, l3Index) }}</h2>
                                              <h2 class="text-primary-three font-poppins fs-13 fw-600" :class="{'text-secondary-one': appearance == 1 || appearance == 2, 'mr-1': indexTitle}" v-else>
                                                {{ getIndexShortTitle(false, 4, l4Index) }}</h2>
                                              <input type="text"
                                                :disabled="!canEdit(project.cans)"
                                                @change="regularUpdate(l4)"
                                                v-model="l4.title"
                                                v-show="viewMode == 1 || viewMode == 2"
                                                class="block text-primary-three w-full fw-600 focus:outline-none bg-transparent font-poppins fs-13 py-3 i-h-50 placeholder-secondary-two cursor-pointer"
                                                autocomplete="off"
                                                :class="{'text-secondary-one': appearance == 1 || appearance == 2}">
                                            </div>
                                            <textarea
                                              :disabled="!canEdit(project.cans)"
                                              class="fs-12 font-poppins text-primary-three selected-a-tiles-bg-indt build-item-color i-pl-15 i-pr-15 h-full w-full bg-transparent pr-content-area cursor-pointer"
                                              style="margin-top: -12px;"
                                              placeholder="Add descriptive text"
                                              @change="regularUpdate(l4)"
                                              v-model="l4.description"
                                              v-show="description && viewMode == 1"
                                              :class="{'text-secondary-one': appearance == 1 || appearance == 2}"
                                              autocomplete="off"
                                            ></textarea>
                                            <h4 class="text-secondary-one font-poppins absolute fs-12 pr-prop-value-pos" v-if="viewMode == 1 || viewMode == 2">{{ getProperty(l4) }}</h4>
                                          </div>
                                        </div>
                                      </div>
                                    </transition-group>
                                  </draggable>
                                </div>
                              </div>
                            </div>
                          </transition-group>
                        </draggable>
                      </div>
                    </div>
                  </div>
                </transition-group>
              </draggable>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
    <context-menu :item="contextmenuItem" :menu="getMenu" :id="'context-menu-'+module_id"
      v-show="contextmenu"
      @appearance="applyAppearnce"
      @indexTitle="applyIndexTitle"
      @background="applyBackground"
      @description="applyDescription"
      @addNew="addNew"
      @deleteLevelItem="deleteLevelItem" />
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canDelete, canCreate, canEdit } = usePermission()
</script>

<script>
  import DualRangeSlider from "@/components/programmatic/DualRangeSlider";
  import ContextMenu from "@/components/programmatic/ContextMenu";
  import { mapGetters } from 'vuex';
  import { getFlattenCollections } from '@/store/modules/global/services';
  import {cloneDeep} from "lodash";

  export default {
      name: "LChild",
      components: {DualRangeSlider, ContextMenu},
      props: ['filteredCollections', 'module_id', 'selectedConnection', 'parentModule', 'mIndex', 'title', 'rootIndex', 'isolatedIds', 'isolate', 'isolatedItemId', 'isolatedModules'],
      created() {
        this.loadProperties();
      },
      data() {
          return {
            assessmentProperties: [],
            properties: [],
            childProperties: [],
            propertySelected: null,
            propertyChildSelected: null,
            viewMode: 1,
            contextmenu: false,
            indexTitle: false,
            description: false,
            contextmenuItem: null,
            appearance: 0,
            lDataName: ['identifier', 'area', 'area-measure', 'measure'],
            l1min: 0,
            l2min: 0,
            l3min: 0,
            l4min: 0,
            l1max: 10,
            l2max: 10,
            l3max: 10,
            l4max: 10,
          }
      },
      watch: {
          selectedConnection: function (val) {
          // if (val == 2) {
              setTimeout(() => {
              this.changeFilterProcess();
              }, 1000);
          // }

          }
      },
      computed: {
          ...mapGetters({
          modules: "programmatic/projectModules",
          scenarioCollections: "programmatic/scenarioCollections",
          activeScenarioCollection: "programmatic/activeScenarioCollection",
          selectedFilterProperties: "programmatic/selectedFilterProperties",
          filterMinValues: "programmatic/filterMinValues",
          filterMaxValues: "programmatic/filterMaxValues",
          colorCodes: "global/colorCodes",
          project: "project/project",
          }),
          getMenu() {
            return {
              description: true,
              isolation: true,
              remove_isolation: false
            };
          },
          getModuleCollectionData() {
            const self = this;
            let module = self.scenarioCollections.find(item => parseInt(item.id) === parseInt(self.module_id));
            if (module) {
              return module.scenario_collections;
            }
            return [];
          },
          getModuleSettings() {
          let module = this.scenarioCollections.find(item => parseInt(item.id) === parseInt(this.module_id));
          if (module) {
              let parsedData = JSON.parse(module.collection_settings);
              this.setIndexTitle(parsedData.index_id);
              return parsedData;
          }
          return null;
          },
      },
      methods: {
          // setAssessment Properties FromDefaultScreen Component using pushAssessmentProperties
          pushAssessmentProperties(data) {
            this.assessmentProperties = data
          },
          setIndexTitle(value) {
            this.indexTitle = value;
          },
          createNewItem(item) {
            let data = cloneDeep(item);
            data.project_id = this.$route.params.id;
            data.title = this.getModuleSettings.collections[data.level].title;
            this.$store.dispatch("programmatic/storeScenarioCollectionItem", data).then(response => {
                if(response && response.data && response.data.data && response.data.data.id) {
                this.$emit("pushToIsolation", {
                    module_id: this.module_id,
                    id: response.data.data.id,
                    parent_id: response.data.data.parent_id,
                });
                }
            });
          },
          applyAppearnce(value) {
          this.appearance = value
          this.contextmenu = false
          },
          applyIndexTitle(value) {
          this.indexTitle = value
          this.contextmenu = false
          },
          applyDescription(value) {
          this.description = value
          this.contextmenu = false
          },
          applyBackground(value) {
          this.contextmenuItem.background_color = value;
          this.regularUpdate(this.contextmenuItem);
          this.contextmenu = false
          },
          getLevelWiseColorCode(level) {
          let indexLevel = level - 1;
          return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
          },
          getBg(level, item) {
          if (item && item.background_color) {
              return item.background_color;
          }
          let collectionSettings = this.getModuleSettings;
          if(collectionSettings && collectionSettings.collections.length > 0) {
              if(collectionSettings.collections[level].custom_bg) {
              return collectionSettings.collections[level].custom_bg;
              }
              let colorCodes = this.getLevelWiseColorCode(level);
              return colorCodes[collectionSettings.collections[level].color];
          }
          return null;
          },
          getIndexTitle(root, parentCodeIndex, index, l1Index = 0, l2Index = 0, l3Index = 0) {
          let nextNumber = index + 1;
          let returnCode = '';
          let moduleSettings = this.getModuleSettings;
          if (moduleSettings && moduleSettings.collections) {
              if (this.indexTitle) {
              returnCode = moduleSettings.collections[parentCodeIndex].index_id ?? '';
              if (root) {
                  let number = parseInt(moduleSettings.collections[parentCodeIndex].index_number) + parseInt(index);
                  returnCode = returnCode + number;
              } else {
                  let serial = parseInt(moduleSettings.collections[1].index_number) + parseInt(l1Index);
                  if(parentCodeIndex > 2) {
                  serial = serial + '.' + (l2Index + 1);
                  if(parentCodeIndex > 3) {
                      serial = serial + '.' + (l3Index + 1);
                  }
                  }
                  returnCode = returnCode + serial + '.' + nextNumber;
                  // returnCode = returnCode + moduleSettings.collections[parentCodeIndex - 1].index_number + '.' + nextNumber;
              }
              }
          }

          return returnCode;
          },
          getIndexShortTitle(root, parentCodeIndex, index) {
          let code = this.getIndexTitle(root, parentCodeIndex, index)
          let lastIndex = ''
          if (code) {
              let splitCode = code.split('.')
              if (splitCode.length > 1) {
              lastIndex = splitCode[splitCode.length - 1]
              }
              else {
              splitCode = code.split('')
              lastIndex = splitCode[splitCode.length - 1]
              }
          }

          return lastIndex
          },
          deleteLevelData(id) {
            // if (e.keyCode === 46) {
              if(confirm("are you sure want to delete this?")) {
                this.$Progress.start();
                this.$store.dispatch("programmatic/deleteLevelData", {
                  project_id: this.$route.params.id,
                  scenario_id: this.$route.params.scenario_id,
                  level_id: id
                }).then(response => {
                  this.$Progress.finish();
                  this.$emit('updateForceKeys');
                });
              }
            // }
          },
        deleteLevelItem() {
          if(confirm("are you sure want to delete this?")) {
            this.$Progress.start();
            this.$store.dispatch("programmatic/deleteLevelData", {
              project_id: this.$route.params.id,
              scenario_id: this.$route.params.scenario_id,
              level_id: this.contextmenuItem.id
            }).then(response => {
              this.$Progress.finish();
              this.contextmenu = false;
              this.$emit('updateForceKeys');
            });
          }
        },
          updateModuleTitle() {
          if(this.parentModule && this.parentModule.id) {
              this.$store.dispatch("project/updateModule", {
              id: this.parentModule.id,
              title: this.parentModule.title
              });
          }
          },
          regularUpdate(obj) {
          this.$store.dispatch("programmatic/updateCollection", obj);
          },
          loadProperties() {
          const self = this;
          self.$store.dispatch("manageProperty/getPropertiesToModule", self.module_id)
          .then(response => {
              if(response && response.data && response.data.data) {
              self.properties = response.data.data;
              if(self.properties.length > 0) {
                  self.propertySelected = self.properties[0].id;
                  self.childProperties = self.properties[0].property_collections;
                  if(self.childProperties.length > 0) {
                  self.propertyChildSelected = self.childProperties[0].id;
                  } else {
                  self.propertyChildSelected = null;
                  }
              } else {
                  self.childProperties = [];
                  self.propertySelected = null;
              }
              this.$store.dispatch("programmatic/getSelectedFilterProperties", {mIndex: self.mIndex, property_id: self.propertyChildSelected});
              } else {
              self.properties = [];
              }
          });
          },
          dropL1Item(e) {
          if(e.from.getAttribute('module-id') && e.to.getAttribute('module-id') && parseInt(e.from.getAttribute('module-id')) === parseInt(e.to.getAttribute('module-id'))) {
              if(parseInt(e.to.getAttribute('level')) === 1) {
              if(e.newIndex !== e.oldIndex) {
                  this.dropTheItem(e, null, null);
              }
              }
          } else {
              alert("You can't drag from a module to another module");
              this.$emit('updateForceKeys');
          }
          },
          dropL2Item(e) {
          if(e.from.getAttribute('module-id') && e.to.getAttribute('module-id') && parseInt(e.from.getAttribute('module-id')) === parseInt(e.to.getAttribute('module-id'))) {
              if(parseInt(e.to.getAttribute('level')) === 2 && e.to.getAttribute('identifier-id')) {
              if((parseInt(e.from.getAttribute('identifier-id')) !== parseInt(e.to.getAttribute('identifier-id'))) || (e.newIndex !== e.oldIndex)) {
                  this.dropTheItem(e, e.from.getAttribute('identifier-id'), e.to.getAttribute('identifier-id'));
              }
              }
          } else {
              alert("You can't drag from a module to another module");
              this.$emit('updateForceKeys');
          }
          },
          dropL3Item(e) {
          if(e.from.getAttribute('module-id') && e.to.getAttribute('module-id') && parseInt(e.from.getAttribute('module-id')) === parseInt(e.to.getAttribute('module-id'))) {
              if(parseInt(e.to.getAttribute('level')) === 3 && e.to.getAttribute('area-id')) {
              if((parseInt(e.from.getAttribute('area-id')) !== parseInt(e.to.getAttribute('area-id'))) || (e.newIndex !== e.oldIndex)) {
                  this.dropTheItem(e, e.from.getAttribute('area-id'), e.to.getAttribute('area-id'));
              }
              }
          } else {
              alert("You can't drag from a module to another module");
              this.$emit('updateForceKeys');
          }
          },
          dropL4Item(e) {
          if(e.from.getAttribute('module-id') && e.to.getAttribute('module-id') && parseInt(e.from.getAttribute('module-id')) === parseInt(e.to.getAttribute('module-id'))) {
              if(parseInt(e.to.getAttribute('level')) === 4 && e.to.getAttribute('area-measure-id')) {
              if((parseInt(e.from.getAttribute('area-measure-id')) !== parseInt(e.to.getAttribute('area-measure-id'))) || (e.newIndex !== e.oldIndex)) {
                  this.dropTheItem(e, e.from.getAttribute('area-measure-id'), e.to.getAttribute('area-measure-id'));
              }
              }
          } else {
              alert("You can't drag from a module to another module");
              this.$emit('updateForceKeys');
          }
          },
          dropTheItem(e, oldParent, newParent) {
          this.$Progress.start();
          let itemId = e.item.getAttribute("item-id");
          let data = {
              parent_id: newParent ? parseInt(newParent) : null,
              old_parent: oldParent ? parseInt(oldParent) : null,
              id: parseInt(itemId),
              index: e.newIndex,
              old_index: e.oldIndex,
              project_id: this.$route.params.id,
              scenario_id: this.$route.params.scenario_id,
              reload: newParent !== oldParent
          };
          this.$store.dispatch("programmatic/reOrderItem", data).then(response => {
              if(response && response.data && response.data.status) {
              this.$store.dispatch("programmatic/loadScenarioCollection", {
                  project_id: this.$route.params.id,
                  scenario_id: this.$route.params.scenario_id
              }).then(response => {
                  this.$emit('updateForceKeys');
                  this.$Progress.finish();
              });
              } else {
              this.$Progress.finish();
              }
          });
          },
          changeChildItem() {
            let child = this.properties.find(item => parseInt(item.id) === parseInt(this.propertySelected));
            if(child && child.property_collections.length > 0)  {
                this.childProperties = child.property_collections;
                this.propertyChildSelected = child.property_collections[0].id;
            } else {
                this.childProperties = [];
                this.propertyChildSelected = null;
            }
          },
          changeChildProp() {
            this.$store.dispatch("programmatic/getSelectedFilterProperties", {mIndex: this.mIndex, property_id: this.propertyChildSelected});
            this.$emit('changeProp')
          },
          getProperty(childItem) {
            let value = 0;
            let propertyCollection = this.childProperties.find(item => parseInt(item.id) === parseInt(this.propertyChildSelected));
            if(propertyCollection)
            {
                let item = childItem
                if(this.assessmentProperties && this.assessmentProperties.length){
                  item = this.assessmentProperties.find(item => parseInt(item.id) === parseInt(childItem.id))
                }

                if(item.properties && item.properties.length > 0) {
                  let property = item.properties.find(property => parseInt(property.property_collection_id) === parseInt(this.propertyChildSelected));
                  if(property && property.sample_value) {
                      value = property.sample_value;
                  }
                }

                if(parseInt(propertyCollection.format) === 1) {
                  return value.toFixed(2);
                } else {
                  let percentValue = value * 10;
                  percentValue = percentValue.toFixed(2);
                  return percentValue.toFixed(2) + '%';
                }
            }
            return value.toFixed(2);
          },

          setActiveScenarioCollection(singleItem) {
            let item = singleItem

            // if assessment selected then it will work
            if(this.assessmentProperties.length){
              this.$emit('setDataSourceType', true)
              let innerItem = this.assessmentProperties.find(item => item.id == singleItem.id)
              if(innerItem){
                item.assess_properties = innerItem.properties
              }
            }else{
              this.$emit('setDataSourceType', false)
            }

            this.$store.commit("programmatic/setActiveBrainstormScenarioCollection", item)
            let data = {}
            data.data = item
            this.$emit("activePropertyOption");
            let hasChild = item.child && item.child && item.child.length > 0;
            this.$store.dispatch('programmatic/propertyChangeable', !hasChild);
            this.$store.commit("programmatic/setActiveScenarioCollection", data)
            this.$store.dispatch("association/getItemAssociations", item.id);
          },
          viewModifier() {
          if (this.viewMode < 3) {
              this.viewMode++
          }
          else {
              this.viewMode = 1
          }

          this.$emit("zoomedFromChild", this.module_id, this.viewMode);
          },
          checkLevel1Filter(item) {
          let status = true;

          if (item.level == 1) {
              let prop = null;
              let selfItem = cloneDeep(item);
              if(this.assessmentProperties.length){
                let innerItem = this.assessmentProperties.find(data => data.id == selfItem.id)
                if(innerItem){
                  item.assess_properties = innerItem.properties
                }
                prop = innerItem.properties.find(el => el.property_collection_id == this.propertyChildSelected && el.sample_value >= this.l1min && el.sample_value <= this.l1max)
              } else {
                prop = item.properties.find(el => el.property_collection_id == this.propertyChildSelected && el.sample_value >= this.l1min && el.sample_value <= this.l1max)
              }

              if (prop) {
                  status = true
              }
              else {
                  this.loadBucket(item);
                  status = false
              }
          }
          return status
          },
          checkLevel2Filter(item) {
          let status = true;

          if (item.level == 2) {
              let prop = null;
              let selfItem = cloneDeep(item);
              if(this.assessmentProperties.length){
                let innerItem = this.assessmentProperties.find(data => data.id == selfItem.id)
                if(innerItem){
                  item.assess_properties = innerItem.properties
                }
                prop = innerItem.properties.find(el => el.property_collection_id == this.propertyChildSelected && el.sample_value >= this.l2min && el.sample_value <= this.l2max)
              } else {
                prop = item.properties.find(el => el.property_collection_id == this.propertyChildSelected && el.sample_value >= this.l2min && el.sample_value <= this.l2max)
              }

              if (prop) {
                  status = true
              }
              else {
                  this.loadBucket(item);
                  status = false
              }
          }
          return status
          },
          checkLevel3Filter(item) {
            let status = true;

            if (item.level == 3) {
                let prop = null;
                let selfItem = cloneDeep(item);
                if(this.assessmentProperties.length){
                  let innerItem = this.assessmentProperties.find(data => data.id == selfItem.id)
                  if(innerItem){
                    item.assess_properties = innerItem.properties
                  }
                  prop = innerItem.properties.find(el => el.property_collection_id == this.propertyChildSelected && el.sample_value >= this.l3min && el.sample_value <= this.l3max)
                } else {
                  prop = item.properties.find(el => el.property_collection_id == this.propertyChildSelected && el.sample_value >= this.l3min && el.sample_value <= this.l3max)
                }

                if (prop) {
                    status = true
                }
                else {
                    this.loadBucket(item);
                    status = false
                }
            }
            return status
          },
          checkLevel4Filter(item) {
          let status = true;

          if (item.level == 4) {
              let prop = null;
              let selfItem = cloneDeep(item);
              if(this.assessmentProperties.length){
                let innerItem = this.assessmentProperties.find(data => data.id == selfItem.id)
                if(innerItem){
                  item.assess_properties = innerItem.properties
                }
                prop = innerItem.properties.find(el => el.property_collection_id == this.propertyChildSelected && el.sample_value >= this.l4min && el.sample_value <= this.l4max)
              } else {
                prop = item.properties.find(el => el.property_collection_id == this.propertyChildSelected && el.sample_value >= this.l4min && el.sample_value <= this.l4max)
              }

              if (prop) {
                  status = true
              }
              else {
                  this.loadBucket(item);
                  status = false
              }
          }
          return status
          },
          loadBucket(item) {
              if(this.selectedConnection == 2) {
                  let collections = getFlattenCollections([item]);
                  collections.forEach(collection => {
                      let exist = this.filteredCollections.find(c => c.id == collection.id);
                      if(!exist) {
                          this.filteredCollections.push(collection);
                      }
                  })
              }
          },
          getRange(val) {
          let rangeSet = [[0,2], [2, 4], [4, 6], [6, 8], [8, 10]]
          let range = rangeSet[0]
          if (val != 0) {
              for (let i = 0; i < rangeSet.length; i++) {
              const el = rangeSet[i];
              if (val > el[0] && val <= el[1]) {
                  range[0] = el[0]
                  range[1] = el[1]
              }
              }
          }
          return range
          },
          changeFilterProcess() {
          let ranges = [[0,10], [0,10], [0,10], [0,10]]
          let ids = []
          let lctrlr = []
          let rctrlr = []
          let lctrlrPos = []
          let rctrlrPos = []
          let sliderSpan = []
          let count = [0, 0,  0, 0]
          let sum = [0, 0,  0, 0]
          let avg = [0, 0,  0, 0]
          this.getModuleCollectionData.forEach(el0 => {
              count[0]++
              let ch0 = el0.properties.find(x => x.property_collection_id == this.propertyChildSelected)
              sum[0] += ch0 ? ch0.sample_value : 0;

              el0.child.forEach(el1 => {
              count[1]++
              let ch1 = el1.properties.find(x => x.property_collection_id == this.propertyChildSelected)
              sum[1] += ch1 ? ch1.sample_value : 0;

              el1.child.forEach(el2 => {
              count[2]++
              let ch2 = el2.properties.find(x => x.property_collection_id == this.propertyChildSelected)
              sum[2] += ch2 ? ch2.sample_value : 0;

                  el2.child.forEach(el3 => {
                  count[3]++
                  let ch3 = el3.properties.find(x => x.property_collection_id == this.propertyChildSelected)
                  sum[3] += ch3 ? ch3.sample_value : 0;
                  })

              })
              })
          })

          if (this.mIndex == 0) {
              for (let i = 0; i < 4; i++) {
              avg[i] = sum[i]/count[i]
              let range = this.getRange(avg[i])
              // ranges[i] = [range[0], range[1]]
              ranges[i] = [0, 10]
              }
          }
          else {

              for (let i = 0; i < 4; i++) {
              ranges[i] = [this.filterMinValues[this.mIndex], this.filterMaxValues[this.mIndex]]
              }

          }

          for (let i = 0; i < 4; i++) {
              ids[i] = document.getElementById('slider-'+this.module_id+'-l'+[i+1])

              if (ids[i]) {
              lctrlr[i] = ids[i].getElementsByClassName('slider-touch-left')[0];
              if (lctrlr[i]) {
                  lctrlrPos[i] = (240/10) * ranges[i][0]
                  lctrlr[i].style.left = lctrlrPos[i]+'px'
              }
              rctrlr[i] = ids[i].getElementsByClassName('slider-touch-right')[0];
              if (rctrlr[i]) {
                  rctrlrPos[i] = (240/10) * ranges[i][1]
                  rctrlr[i].style.left = rctrlrPos[i]+'px'
              }
              sliderSpan[i] = ids[i].querySelector('.slider-line span')
              sliderSpan[i].style.marginLeft = lctrlr[i].style.left
              sliderSpan[i].style.width = (rctrlrPos[i]-lctrlrPos[i])+'px'
              }
          }

          this.l1min = ranges[0][0]
          this.l1max = ranges[0][1]
          this.l2min = ranges[1][0]
          this.l2max = ranges[1][1]
          this.l3min = ranges[2][0]
          this.l3max = ranges[2][1]
          this.l4min = ranges[3][0]
          this.l4max = ranges[3][1]

          },
          menuHandler(e, item) {
            this.contextmenu = true
            this.contextmenuItem = item
            let top = e.clientY
            let left = e.clientX
            let context = document.getElementById('context-menu-'+this.module_id)
            context.style.left = left+'px'
            context.style.top = top+'px'
          },
          closeMenuHandler(e) {
              var context = document.getElementById('context-menu-'+this.module_id);
              if (!context.contains(e.target) && !e.target.closest('.action_items')) {
                this.contextmenu = false
              }
          },
          getData(items) {
            let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.module_id);
            if(moduleInIsolation && moduleInIsolation.isolate) {
              return items.filter(data => moduleInIsolation.isolatedIds.includes(data.id));
            }
            return items;
          },
          addNew(value) {
            let parent = this.contextmenuItem
            let chcount = parent.child.length
            let order = 1;
            if (chcount > 0) {
                order = chcount + 1
            }
            let obj = {};
            obj.parent_id = parent.id;
            obj.title = this.getModuleSettings.collections[parent.level + 1].title;
            obj.level = parent.level + 1;
            obj.item_order = order;
            obj.order = order;
            obj.module_id = parent.module_id;
            obj.scenario_id = parent.scenario_id;
            this.$Progress.start();
            this.createNewItem(obj);
            this.contextmenu = false;
          },
      }
  }
</script>

<style scoped>
.action_items{
  display: flex;
  gap: 6px;
  position: absolute;
  top: 100%;
  padding: 8px 0;
  visibility: hidden;
}
.prog-lc-item:is(.active) .action_items{
  visibility: visible;
}
.prog-child-content-div {
  height: 810px;
}
.prog-l1-div {
  width: 100%;
  height: 805px;
}
.prog-l1-div:first-child {
  padding-top: 18px;
}

.prog-lc-item {
  min-height: 50px;
}
.no-shadow {
  box-shadow: none;
}
.prog-mode-1 {
  width: 264px;
}
.prog-mode-2 {
  width: 40px;
  justify-content: center;
  align-items: center;
}
.prog-mode-2 div {
  padding-left: 0;
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  --transform-rotate: -90deg;
}
.prog-mode-2 h2 {
  padding-left: 0;
}

.prog-slider-h {
  height: 23px;
}
.prog-min-expanded {
  min-height: 50px;
  min-width: 264px
}
.prog-min-minimized {
  min-width: 40px;
}

.item-top-mt {
  margin-top: 32px;
}
.item-top-mt:first-child {
  margin-top: 0;
}
.all-border {
  border: 1px;
  border-style: solid;
}
.bottom-border {
  border-bottom: 1px;
  border-style: solid;
}
.prog-lc-width {
  width: 264px;
}
.i-h-description {
  height: 65px;
}
</style>
