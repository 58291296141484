<template>
  <div>
    <div class="relative i-border-1 border-secondary-two rounded w-full i-mt-30 i-h-50"
         @click="placeholder.search = ''"
         v-click-outside="hideSearchPlaceholder">
      <input
          type="text"
          class="focus:outline-none py-2 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two i-h-50"
          :placeholder="placeholder.search"
          v-model="searchQuery"
      />
    </div>

    <div class="bg-primary-three shadow-one rounded mt-8 mb-8 relative flex items-center px-4 i-h-50 bld-level-w">
      <h2 class="font-poppins fs-14 fw-600 text-primary-one">{{ selectedTitle }}</h2>
      <div v-click-outside="hideDropdown">
        <div class="absolute bld-global-chevron w-10 i-h-50 cursor-pointer flex justify-center items-center"
             @click="toggleDropdown()">
          <img src="/images/icons/chevron_down.svg" alt="chevron" :class="{'rotate-180': dropdownStat}">
        </div>
        <div
            class="absolute bg-primary-three bld-level-w text-left px-5 py-3 rounded shadow-one bld-global-opt flex flex-col"
            v-if="dropdownStat">
          <div>
            <h3 class="fs-14 fw-600 text-primary-one font-poppins">Workspace Library</h3>
            <h3 class="fs-12 text-secondary-one font-poppins mt-3">Filters:</h3>
            <div class="flex justify-between mt-3">
              <h2 class="fs-12 font-poppins text-primary-three bg-primary-four flex justify-center items-center bld-global-level rounded cursor-pointer" :class="{'border-2 border-primary-one': level === 1}" @click="setLevel(1)">Level 1</h2>
              <h2 class="fs-12 font-poppins text-primary-three bg-static-one flex justify-center items-center bld-global-level rounded cursor-pointer" :class="{'border-2 border-primary-one': level === 2}" @click="setLevel(2)">Level 2</h2>
              <h2 class="fs-12 font-poppins text-primary-three bg-static-two flex justify-center items-center bld-global-level rounded cursor-pointer" :class="{'border-2 border-primary-one': level === 3}" @click="setLevel(3)">Level 3</h2>
              <h2 class="fs-12 font-poppins text-primary-three bg-static-three flex justify-center items-center bld-global-level rounded cursor-pointer" :class="{'border-2 border-primary-one': level === 4}" @click="setLevel(4)">Level 4</h2>
            </div>
          </div>
          <div class="flex flex-col mt-5">
            <div class="flex justify-between items-center i-n-mr-10">
              <h3 class="fs-14 fw-600 text-primary-one font-poppins">Projects </h3>
              <img src="/images/icons/chevron_down.svg" alt="icon" class="cursor-pointer" @click="menuExpand = !menuExpand" :class="{'rotate-180': menuExpand}">
            </div>
            <div v-show="menuExpand" class="mt-4 bld-global-project-div overflow-y-auto ws-scrollbar">
              <div class="font-poppins fs-12 fw-600 cursor-pointer text-primary-one opacity-75"
                   v-for="(projectItem, prIndex) in buildGlobalProjects"
                   :key="prIndex"
                   :class="{'mt-4': prIndex > 0, 'fs-14 opacity-100': projectSelection === projectItem.id}"
                   @click="toggleProjectSelection(prIndex, projectItem.id)">
                {{ projectItem.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="global-navigation overflow-y-auto">
      <draggable
          :disabled="!canEdit(project.cans)"
          :list="currentList"
          :group="{ name: dropGroup, pull: 'clone' }"
          v-bind="dragOptions"
          :clone="cloneItem"
          @start="startTheDrag($event)"
          @end="dropTheItem($event)" class="pl-10 pr-10">
        <div class="bg-primary-three bld-global-h bld-level-w rounded shadow-two relative cursor-move i-bg-img-fit flex flex-col justify-end"
             v-for="(item, index) in filterResult"
             :key="index"
             :class="{'mt-8': index > 0, 'cursor-pointer': assessmentScreen, 'cursor-move': !assessmentScreen}"
             :style="{'background-image': (item.file?.file_path) ? `url(${item.file?.file_path})` : 'url(' + require(`../../../assets/images/${demoImages[getRandomIndex()]}`) + ')'}"
             :level="item.level"
             :id="item.id">
          <div class="absolute bld-global-item bld-level-w bg-primary-three py-4 px-4">
            <h2 class="font-poppins fs-14 text-primary-one fw-600 ellipses text-left">
              {{ item.title }}</h2>
            <p class="font-poppins fs-12 text-left text-primary-one">
              {{ item.description && item.description.length > 117 ? item.description.substr(0, 117) + '...' : item.description }}
            </p>
          </div>
          <div class="absolute bld-tag bld-tag-size bg-primary-four rounded-sm flex items-center justify-center" :style="{'background': getTagBg(item.module_id, item.level)}">
            <h2 class="font-poppins fs-12 text-primary-three px-2 text-center">{{ item.tag ? item.tag : 'Add tag' }}</h2>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canDelete, canCreate, canEdit } = usePermission()
</script>

<script>
import {mapGetters} from "vuex";

export default {
  name: "GlobalList",
  props: ['assessmentScreen'],
  created() {
    const self = this;
    self.$store.dispatch("project/loadGlobalModulesData", {
      project: false,
      id: parseInt(self.$route.params.id)
    });
    self.getWorkspaceData();
  },
  data() {
    return {
      currentList: [],
      menuExpand: true,
      level: 1,
      projectSelection: null,
      selectedTitle: 'Workspace Library',
      dropGroup: '',
      dropdownStat: false,
      loadType: 0,
      loadTypeArray: ['Global Library', 'Non Adapted', 'Template'],
      placeholder: {
        search: "Search"
      },
      groupList: ['identifier', 'area', 'area_measure', 'measure'],
      searchQuery: null,
      parentObj: {
        l1: null,
        l2: null,
        l3: null
      },
      demoImages: ['bld_demo_1.jpg', 'bld_demo_1.jpg', 'bld_demo_1.jpg']
    }
  },
  computed: {
    ...mapGetters({
      libraryList: "programmatic/libraryList",
      workspaceModules: "programmatic/workspaceModules",
      globalLibrariesData: "project/globalLibrariesData",
      buildGlobalProjects: "project/buildGlobalProjects",
      globalModules: "project/globalModules",
      colorCodes: "global/colorCodes",
      project: "project/project",
    }),
    dragOptions() {
      return {
        animation: 200,
        disabled: this.assessmentScreen || this.connectionSelection === 0,
        ghostClass: "ghost",
      };
    },
    filterResult() {
      const self = this;
      if (self.searchQuery) {
        return self.globalLibrariesData.filter((item) => {
          return self.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => {
                if (item.tag) {
                  return item.title.toLowerCase().includes(v) || item.tag.toLowerCase().includes(v);
                }
                return item.title.toLowerCase().includes(v);
              });
        });
      }
      return self.globalLibrariesData;
    }
  },
  methods: {
    getRandomIndex(min = 0, max = 2) {
      let index = Math.random() * (max - min) + min;
      return parseInt(index);
    },
    toggleDropdown() {
      this.dropdownStat = !this.dropdownStat;
    },
    hideDropdown() {
      this.dropdownStat = false;
    },
    getBg(moduleId, level) {
      let item = this.workspaceModules.find(item => item.id === moduleId);
      if (item) {
        let settingsObj = JSON.parse(item.collection_settings);
        if (settingsObj) {
          return settingsObj.collections[level].color;
        }
        return null;
      }
      return null;
    },
    setLoadType(value) {
      const self = this;
      self.loadType = value;
      self.hideDropdown();
      if (value === 0) {
        let id = self.workspaceModules[0].project_id;
        if (id) {
          self.$store.dispatch("programmatic/loadGlobalLibraries", id);
        }
      } else if (value === 1) {
        self.$store.dispatch("programmatic/loadNonAdaptedList", {
          project_id: self.$route.params.id,
          scenario_id: self.$route.params.scenario_id,
        });
      } else if (value === 2) {
        self.$store.dispatch("programmatic/loadTemplate");
      }
    },
    hideSearchPlaceholder() {
      this.placeholder.search = 'Search';
    },
    cloneItem() {
      return;
    },
    startTheDrag(e) {
      let level = e.item.getAttribute('level');
      this.dropGroup = this.groupList[level - 1];
    },
    dropTheItem(e) {
      const self = this;
      let level = e.item.getAttribute('level');
      let copiedId = e.item.getAttribute('id');
      let parentId = null;
      if (e.to.getAttribute("identifier-id")) {
        parentId = e.to.getAttribute("identifier-id");
      } else if (e.to.getAttribute("area-id")) {
        parentId = e.to.getAttribute("area-id");
      } else if (e.to.getAttribute("area-measure-id")) {
        parentId = e.to.getAttribute("area-measure-id");
      }

      let moduleId = e.to.getAttribute("module-id") ? e.to.getAttribute("module-id") : e.to.getAttribute("id");
      let data = {
        copied_id: copiedId,
        index: e.newIndex,
        module_id: moduleId,
        level: level,
        parent_id: parentId,
        scenario_id: self.$route.params.scenario_id,
        project_id: self.$route.params.id,
        global: true,
        nonAdapted: self.loadType === 1
      };

      let canvasId = null;
      let parentNode = e.to.parentNode;
      if(parentNode) {
        canvasId = parentNode.getAttribute("canvas-id");
      }

      if (parentId || e.to.getAttribute("module-id") || canvasId) {
        self.$Progress.start();
        self.$store.dispatch("programmatic/storeDataFromLibrary", data).then(response => {
          if(response && response.data && response.data.data && response.data.data.id) {
            this.$emit("pushToIsolation", {
              module_id: moduleId,
              id: response.data.data.id,
              parent_id: response.data.data.parent_id,
            });
          }
          self.$Progress.start();
          self.$store.dispatch("programmatic/loadScenarioCollection", {
            project_id: self.$route.params.id,
            scenario_id: self.$route.params.scenario_id
          }).then(response => {
            self.$Progress.finish();
            this.$emit('updateForceKeys');
          });
          self.$Progress.finish();
        });
      }
      self.dropGroup = '';
    },
    findParent(parentId, level) {
      if (level === 4) {
        this.parentObj.l3 = parentId;
      } else if (level === 3) {
        this.parentObj.l2 = parentId;
      } else if (level === 2) {
        this.parentObj.l2 = parentId;
      }
      let item = this.libraryList.find(item => parseInt(item.id) === parseInt(parentId));
      if (item && item.parent_id) {
        this.findParent(item.parent_id, item.level);
      }
    },
    getWorkspaceData() {
      const self = this;
      self.$store.dispatch("project/loadGlobalLibrariesData", {
        project: false,
        id: parseInt(self.$route.params.id),
        level: self.level
      })
    },
    getProjectData(projectId) {
      const self = this;
      self.$store.dispatch("project/loadGlobalLibrariesData", {
        project: true,
        id: projectId,
        level: self.level
      });
    },
    setLevel(level) {
      this.level = level;
      if(this.projectSelection) {
        this.$store.dispatch("project/loadGlobalModulesData", {
          project: true,
          id: parseInt(this.projectSelection)
        });
        this.getProjectData(this.projectSelection);
      } else {
        this.$store.dispatch("project/loadGlobalModulesData", {
          project: false,
          id: parseInt(this.$route.params.id)
        });
        this.getWorkspaceData();
      }
    },
    toggleProjectSelection(index, id) {
      this.selectedTitle = this.buildGlobalProjects[index].name;
      this.projectSelection = id;
      this.getProjectData(id);
      this.toggleDropdown();
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    getTagBg(moduleId, level) {
      const self = this;
      let module = self.globalModules.find(mdl => parseInt(mdl.id) === parseInt(moduleId));
      if(module) {
        let collections = JSON.parse(module.collection_settings).collections;
        if(collections[level].custom_bg) {
          return collections[level].custom_bg;
        }
        let colorCodes = this.getLevelWiseColorCode(level);
        return colorCodes[collections[level].color];
      }
      return null;
    },
  },
}
</script>

<style scoped>
.build-search-input::placeholder {
  color: #a5a5a5;
}

.build-search-input {
  color: #E7E7E7;
}
</style>
