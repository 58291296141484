<template>
  <div class="property-body fs-14 text-secondary-one">
    <div class="mb-6 relative">
        <img :src="(activeScenarioCollection.file) ? activeScenarioCollection?.file?.file_path : '/images/build/demo_image_1.jpg'" alt="image" class="block w-full _my_img_size">
        <label v-if="canEdit(project.cans)" class="absolute right-1 bottom-1 property-image-icon cursor-pointer">
          <input @input="(e)=>{onChange(e), updateImage(
              {file, id:activeScenarioCollection.id},
                ()=>activeScenarioCollection.file = {file_path: previewURL}
              )}"
                 :disabled="!canEdit(project.cans)"
              type="file" hidden accept="image/x-png,image/jpeg" />

          <button class="pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
              <path d="M0 12.3477H12V10.3477H0V12.3477ZM0 7.34766H18V5.34766H0V7.34766ZM0 2.34766H12V0.347656H0V2.34766Z" fill="#E2E2E2"/>
            </svg>
          </button>
        </label>
    </div>
    <div class="mb-10" v-if="activeScenarioCollection && Object.keys(activeScenarioCollection).length !== 0">
      <div class="flex items-center">
<!--        <span class="text-primary-one ls-5 font-poppins fs-14 fw-600">{{ activeScenarioCollection.index_code }}</span>-->
        <input type="text" class="focus:outline-none bg-transparent w-full placeholder-secondary-two cursor-pointer truncate text-primary-one fw-600 ls-5 font-poppins fs-14 truncate"
               v-model="activeScenarioCollection.title"
               :disabled="!canEdit(project.cans)"
               @change="updateItem(activeScenarioCollection)" />
      </div>
      <!-- <p v-if="activeScenarioCollection && Object.keys(activeScenarioCollection).length !== 0"
         :contenteditable="canEdit(project.cans)"
         @input="updateItem(activeScenarioCollection, $event)"
         class="fs-12 w-full bg-transparent prop-item-desc break-words none-resize">
        {{ activeScenarioCollection.description }}
      </p> -->
      <TextareaAutoHeight
        v-if="activeScenarioCollection && Object.keys(activeScenarioCollection).length !== 0"
        v-model="activeScenarioCollection.description"
        :isDisabled="!canEdit(project.cans)"
        @onType="updateItem(activeScenarioCollection)"
      />
    </div>
    <router-link :to="{path: '/build/'+$route.params.id+'/properties/'+activeScenarioCollection.module_id+'/'+activeScenarioCollection.id}" :event="activeScenarioCollection ? 'click' : ''" :title="!activeScenarioCollection.id ? 'Please, select an item first' : ''">
      <div class="flex justify-between cursor-pointer items-center pb-3 mb-8 i-border-b-1 border-secondary-two">
        <p class="fw-500 fs-12 text-secondary-two">
          Manage properties and Association
        </p>
        <img src="/images/icons/build/manage_property.svg" alt="icon" />
      </div>
    </router-link>

    <div class="p-0 bg-transparent relative">
      <div class="i-border-b-1 border-secondary-two i-mb-30" v-for="(dataset, index) in getModuleProperties" :key="index">
        <div class="flex justify-between items-center cursor-pointer i-pb-25 fw-600">
<!--          <span class="">{{ dataset.title }}</span>-->
          <input type="text" v-model="dataset.title" class="bg-transparent text-primary-one fw-600 w-full" @input="updateProperty(dataset)" />
          <img :id="'down-arrow-'+index" class="p-2" src="/images/icons/build/chevron_down.svg"
               alt="image" @click="statusShow(index)">
          <img :id="'up-arrow-'+index" class="p-2 hidden" src="/images/icons/build/chevron_up.svg"
               alt="image" @click="statusHide(index)">
        </div>
        <div class="hidden" :id="'value-cont-'+index">
          <div class="flex justify-start items-center i-mb-25" v-for="(prop, pindex) in dataset.property_collections.filter(el => el.is_visible)"
               :key="pindex" @mouseover="showValue(index, pindex)" @mouseleave="hideValue(index, pindex)">
<!--            <p class="prop-items i-mr-15">{{ prop.title }}</p>-->
            <input type="text" v-model="prop.title" class="bg-transparent prop-items i-mr-15" @input="updateParameter(prop)" />
            <property-sample v-if="!prop.option || prop.option == 1"
              :property="prop"
              :index="index"
              :p-index="pindex"
              :item="getActiveScenarioCollection()"
              @updateParameterData="getUpdateData"
              :hasAssessment="hasAssessment"
            />
            <input-property v-if="prop.option == 2"
                :property="prop"
                :index="index"
                :p-index="pindex"
                :item="activeScenarioCollection"
                @updateParameterData="getUpdateData" />
          </div>
        </div>
      </div>
    </div>
    <div class="prop-set-div i-border-b-1 border-secondary-two">
      <div class="flex justify-between items-center cursor-pointer pb-6 text-secondary-one fw-600" @click="showAssociated = !showAssociated">
        <span>Associated content</span>
        <img src="/images/icons/build/chevron_down.svg" alt="icon" class="p-2" :class="{'rotate-180': showAssociated}">
      </div>
      <div v-if="showAssociated || canEdit(project.cans)" class="flex justify-between items-center pb-3" v-for="(item, index) in itemAssociations" :key="index">
        <p class="assoc-items i-pr-15 text-secondary-one fs-14">
          {{ item.associated ? item.associated.title : '' }}
        </p>
        <img :disabled="!canDelete(project.cans)" src="/images/icons/build/off_outline_close.svg" alt="image" class="p-2 cursor-pointer"
             @click="removeFromAssociation(item.id)">
      </div>
    </div>
  </div>
</template>

<script setup>
  import axios from 'axios'
  import TextareaAutoHeight from '@/elements/inputs/TextareaAutoHeight'
  import { useFile } from '@/composable/useFile.js'
  import usePermission from '@/composable/usePermission.js'

  const { onChange, previewURL, file } = useFile()
  const { canDelete, canCreate, canEdit } = usePermission()
</script>

<script>
import {mapGetters} from 'vuex';
import PropertySample from "../../properties/PropertySample";
import InputProperty from "@/components/properties/InputProperty";
import {cloneDeep} from "lodash";

export default {
  name: "Property",
  components: {InputProperty, PropertySample},
  props: ['assessmentScreen', 'hasAssessment'],
  data() {
    return {
      showAssociated: true
    }
  },
  computed: {
    ...mapGetters({
      properties: "programmatic/collectionProps",
      activeScenarioCollection: "programmatic/activeScenarioCollection",
      scenarioCollections: "programmatic/scenarioCollections",
      activeModule: "build/navigatorModule",
      associatedData: "association/associatedData",
      itemAssociations: "association/itemAssociations",
      propertyChangeable: "programmatic/propertyChangeable",
      project: "project/project",
    }),
    getModuleProperties() {
      const self = this;
      let module= {}
      let moduleId = self.activeScenarioCollection ? self.activeScenarioCollection.module_id : null;

      if (moduleId && self.scenarioCollections) {
        module = self.scenarioCollections.find(item => item.id == moduleId);
      }
      // console.log(moduleId, self.activeScenarioCollection)
      if (module) {
        return module.properties.filter(el => el.is_visible);
      }
      return [];
    },
    getModuleCollections() {
      const self = this;
      let module= {}
      let moduleId = self.activeScenarioCollection ? self.activeScenarioCollection.module_id : null;
      if (moduleId && self.scenarioCollections) {
        module = self.scenarioCollections.find(item => item.id == moduleId);
      }
      if (module) {
        return module.scenario_collections;
      }
      return [];
    },
  },
  watch: {
    activeScenarioCollection: function (newProp, oldProp) {
      // console.log(newProp);
    }
  },
  methods: {
    async updateImage({file, id}, callback) {
      let formData = new FormData()
      formData.append('id', id)
      formData.append('image', file)
      await axios.post('projects/scenarios/collections/image', formData)
      callback()
    },
    getActiveScenarioCollection(){
      // this.activeScenarioCollection
      let item = cloneDeep(this.activeScenarioCollection)
      if(this.hasAssessment){
        item.properties = item.assess_properties
      }
      return item;
    },
    statusShow(index) {
      let cont = document.getElementById('value-cont-' + index)
      let up = document.getElementById('up-arrow-' + index)
      let down = document.getElementById('down-arrow-' + index)
      cont.style.display = 'block'
      up.style.display = 'block'
      down.style.display = 'none'
    },
    statusHide(index) {
      let cont = document.getElementById('value-cont-' + index)
      let up = document.getElementById('up-arrow-' + index)
      let down = document.getElementById('down-arrow-' + index)
      cont.style.display = 'none'
      up.style.display = 'none'
      down.style.display = 'block'
    },
    updateTree(tree, propid, value) {
      tree.forEach(el => {
        if (el.id == this.activeScenarioCollection.id) {
          let prop = el.properties.find(p => p.property_collection_id == propid)
          if (prop) {
            prop.sample_value = value
          }
        }
        else {
          this.updateTree(el.child, propid, value)
        }
      });
    },
    getUpdateData(obj) {
      let updateItem = this.activeScenarioCollection.properties.find(p => p.property_collection_id == obj.property_collection_id);
      if(!updateItem) {
        this.activeModuleCollection.properties.push({
          scenario_collection_id: this.activeScenarioCollection.id,
          module_id: this.activeScenarioCollection.module_id,
          property_collection_id: obj.property_collection_id,
          sample_value: obj.sample_value
        });
      }
      let parameterData = this.$lodash.cloneDeep(obj);
      parameterData.sc_id = this.activeScenarioCollection.id;
      parameterData.scenario_id = this.$route.params.scenario_id;
      parameterData.project_id = this.$route.params.id;
      parameterData.value = obj ? parseFloat(obj.sample_value) : 0;
      parameterData.id = obj.property_collection_id;
      parameterData.module_id = this.activeScenarioCollection.module_id;
      let tree = this.getModuleCollections
      this.updateTree(tree, parameterData.id, parameterData.value)
      this.$store.dispatch("programmatic/updatePropertyItem", parameterData);
    },
    getAssociatedData() {
      if (this.activeScenarioCollection) {
        this.$store.dispatch("association/getAssociatedData", this.activeScenarioCollection.module_collection_id);
      }
    },
    removeFromAssociation(id) {
      const self = this;
      self.$store.dispatch("association/removeAssociation", id);
    },
    showValue(index, pindex) {
      let value = document.getElementById('value-hover-' + index + '-' + pindex)
      value.style.display = 'block'
    },
    hideValue(index, pindex) {
      let value = document.getElementById('value-hover-' + index + '-' + pindex)
      value.style.display = 'none'
    },
    updateItem(obj, e = null) {
      let objData = Object.assign({}, obj);
      if(e) {
        objData.description = e.target.innerText;
      }
      this.$store.dispatch("programmatic/updateCollection", objData);
    },
    autoHeight(e) {
      let textarea = e.target
      textarea.addEventListener("input", function (e) {
        this.style.height = "auto";
        this.style.height = this.scrollHeight + "px";
      });
    },
    updateProperty(property) {
      this.$store.dispatch("manageProperty/updateProperty", {
        id: property.id,
        title: property.title,
        module_id: property.module_id
      });
    },
    updateParameter(parameter) {
      this.$store.dispatch("manageProperty/updatePropertyCollectionItem", {
        id: parameter.id,
        title: parameter.title
      });
    }
  },
  mounted() {
    this.getAssociatedData();
  }
}
</script>

<style scoped>
.property-body {
  padding: 35px 0;
  max-height: 907px;
  overflow-y: auto;
  overflow-x: hidden;
}

.prop-items {
  width: 150px;
}

.assoc-items {
  width: 250px;
}

.track-box {
  width: 140px;
}

.value-track, .progress-track, .range {
  height: 2px;
}

.range {
  outline: none;
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
  width: 20px;
  -webkit-appearance: none;
  height: 10px !important;
  cursor: pointer;
  background: #00A7FE;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 1px;
}
</style>
