    import axios from 'axios'
    import routes from '@/routes/routes.js'

    export default function useAssessment()
    {
        const getAssessment = async (module_id) => {
            const scenario_id = routes.currentRoute.params.scenario_id
            if(scenario_id && module)
            {
                let res = await axios.get(`projects/assessments/system/scenariowise`, { params: { scenario_id, module_id } })
                let data = res.data.data
                data.unshift({ id: 0, title: 'Properties', selected: true, isProperties: true })
                
                return data
            }
            return [{ id: 0, title: 'Properties', selected: true }]
        }

        const getSystemDataWithProperties = async (assessmentId) => {
            let res = await axios.get(`projects/assessments/system/samples/${assessmentId}/values`)
            let data = res.data.data
            return data
        }

        return {
            getAssessment,
            getSystemDataWithProperties
        }
    }