<template>
  <div class="" :class="{'prog-module-initial-box-ml': index > 0}"  :id="'prog_visual_module_' + rootModule.id">
    <div class="flex items-center i-border-b-1 border-solid pb-4 border-secondary-five">
      <div class="flex items-center" style="width: 435px">
        <h2 class="fs-14 font-poppins fw-600 text-primary-one uppercase mr-1 pr-v-module-item text-left" :title="rootModule.title">
          {{ getModuleTitle() }}
        </h2>
        <select class="fs-14 font-poppins bg-transparent text-secondary-two pr-select-dropdown visual-pr-select-dropdown" v-model="property" @change="setChildSet()">
          <option
              v-for="(propRoot, propIndex) in rootModule.properties"
              :key="propIndex"
              :value="propRoot.id">
            {{ propRoot.title }}
          </option>
        </select>
      </div>
      <div class="flex items-center justify-start i-ml-20 relative">
        <select v-for="(child, cIndex) in countChild" :key="cIndex" :class="{'ml-4': cIndex > 0}" class="fs-14 font-poppins bg-transparent text-secondary-two pr-select-dropdown" v-model="selectedChildProperties[cIndex]" style="width: 60px">
          <option
              v-for="(childProp, childPropIndex) in childProperties.filter(item => !item.option || parseInt(item.option) === 1)"
              :key="childPropIndex"
              :value="childProp.id">
            {{ childProp.suffix }}
          </option>
        </select>
        <img src="/images/icons/programmatic/visual_plus_icon.svg" style="right: -15px" alt="image" class="absolute visual-plus-img ml-5 cursor-pointer opacity-40" @click="manageChildCount()">
      </div>
    </div>
    <div class="pr-visualizer-item-root-box flex flex-col mt-4">
      <div class="i-border-b-1 border-solid pb-4 border-secondary-five flex visual-item-root-part" v-for="(l1, l1Index) in getData(rootModule.scenario_collections)" :key="l1Index"
           v-if="getItemVisibility(l1)">
        <div class="visual-items flex">
          <div class="visual-item cursor-pointer shadow-two rounded-sm"
               :style="{'background': getBackground(l1)}"
               @click="setActiveItem(l1), getAssociation(l1)"></div>
          <property :item="l1" :rootModule="rootModule" :index="l1Index" :countChild="countChild" :rootProperty="property"
                    :selectedChildProperties="selectedChildProperties" :associatedItems="associatedItems" :indirectAssociatedData="indirectAssociatedData"
                    :associatedOpt="associatedOpt" :assocChoosedItem="assocChoosedItem"
                    @pushIndirectAssociatedData="pushIndirectAssociatedData" />

          <div class="flex flex-col visual-item-pl cursor-pointer">
            <div class="flex visual-item-part" v-for="(l2, l2Index) in getData(l1.child)" :key="l2Index" v-if="getItemVisibility(l2)">

              <div class="visual-item cursor-pointer shadow-two rounded-sm"
                   :style="{'background': getBackground(l2)}"
                   @click="setActiveItem(l2), getAssociation(l2)"></div>
              <property :item="l2" :rootModule="rootModule" :index="l2Index" :countChild="countChild" :rootProperty="property"
                        :selectedChildProperties="selectedChildProperties" :associatedItems="associatedItems" :indirectAssociatedData="indirectAssociatedData"
                        :associatedOpt="associatedOpt" :assocChoosedItem="assocChoosedItem"
                        @pushIndirectAssociatedData="pushIndirectAssociatedData" />

              <div class="flex flex-col">
                <div class="flex visual-item-part" v-for="(l3, l3Index) in getData(l2.child)" :key="l3Index" v-if="getItemVisibility(l3)">

                  <div class="visual-item visual-item-pl cursor-pointer shadow-two rounded-sm"
                       :style="{'background': getBackground(l3)}"
                       @click="setActiveItem(l3), getAssociation(l3)"></div>
                  <property :item="l3" :rootModule="rootModule" :index="l3Index" :countChild="countChild" :rootProperty="property"
                            :selectedChildProperties="selectedChildProperties" :associatedItems="associatedItems" :indirectAssociatedData="indirectAssociatedData"
                            :associatedOpt="associatedOpt" :assocChoosedItem="assocChoosedItem"
                            @pushIndirectAssociatedData="pushIndirectAssociatedData" />

                  <div class="flex flex-col">
                    <div class="flex visual-item-part" v-for="(l4, l4Index) in getData(l3.child)" :key="l4Index" v-if="getItemVisibility(l1)">
                      <div class="visual-item visual-item-pl cursor-pointer shadow-two rounded-sm"
                           :style="{'background': getBackground(l4)}"
                           @click="setActiveItem(l4), getAssociation(l4)"></div>
                      <property :item="l4" :rootModule="rootModule" :index="l4Index" :countChild="countChild" :rootProperty="property"
                                :selectedChildProperties="selectedChildProperties" :associatedItems="associatedItems" :indirectAssociatedData="indirectAssociatedData"
                                :associatedOpt="associatedOpt" :assocChoosedItem="assocChoosedItem"
                                @pushIndirectAssociatedData="pushIndirectAssociatedData" />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Property from "./Property";
import {mapGetters} from "vuex";

export default {
  name: "ModuleData",
  components: {Property},
  props: ['rootModule', 'index', 'assocChoosedItem', 'activeModuleId', 'associatedItems', 'indirectAssociatedData', 'sortByAssociatedOpt', 'associatedOpt', 'lastClickedItem',
    'isolatedModules'],
  created() {
    const self = this;
    if(self.rootModule.properties.length > 0) {
      self.property = this.rootModule.properties[0].id;
      if(self.property) {
        let defaultProperty = this.rootModule.properties[0];
        if(defaultProperty.property_collections.length > 0) {
          self.childProperties = defaultProperty.property_collections;
          self.selectedChildProperties.push(defaultProperty.property_collections[0].id);
        }
      }
    }
  },
  data() {
    return {
      property: null,
      childProperties: [],
      selectedChildProperties: [],
      countChild: 1,
      screenWiseVisualDiv: 480
    }
  },
  computed: {
    ...mapGetters({
      visualCollections: "programmatic/scenarioCollections",
      colorCodes: "global/colorCodes"
    })
  },
  methods: {
    getBgColor(module, item, level) {
      if (item && item.background_color) {
        return item.background_color;
      } else {
        if(module.collection_settings) {
          let collectionSettings = JSON.parse(module.collection_settings);
          if (collectionSettings) {
            if (collectionSettings && collectionSettings.collections.length > 0) {
              if(collectionSettings.collections[level].custom_bg) {
                return collectionSettings.collections[level].custom_bg;
              }
              let colorCodes = this.getLevelWiseColorCode(level);
              return colorCodes[collectionSettings.collections[level].color];
            }
          }
          return null;
        }
        return null;
      }
    },
    setChildSet() {
      this.countChild = 1;
      this.selectedChildProperties = [];
      let property = this.rootModule.properties.find(item => parseInt(item.id) === parseInt(this.property));
      if(property) {
        this.childProperties = property.property_collections;
        if(this.childProperties.length > 0) {
          this.selectedChildProperties.push(this.childProperties[0].id);
        }
      }
    },
    manageChildCount() {
      if(this.childProperties.length > 0 &&  this.selectedChildProperties.length < this.childProperties.length) {
        this.countChild++;
        this.selectedChildProperties.push(this.childProperties[this.countChild - 1].id);
      }
    },
    getWidth() {
      return this.screenWiseVisualDiv + ((this.countChild - 1) * 60) + 'px';
    },
    getBackground(item) {
      const self = this;
      if(self.assocChoosedItem && self.assocChoosedItem.length > 0) {
        let checkExistence = self.associatedItems.findIndex(assocItem => parseInt(assocItem.associated_id) === parseInt(item.id));
        if(checkExistence >= 0) {
          return "#0075FF";
        }
        let itemIndirectData = self.indirectAssociatedData.findIndex(inDirItem => parseInt(inDirItem.id) === parseInt(item.id));
        if(itemIndirectData >= 0) {
          return "#0075FF";
        }
        let itemExistence = self.assocChoosedItem.find(assocItem => parseInt(assocItem.id) === parseInt(item.id));
        if(itemExistence) {
          return "#0075FF";
        }
        return self.getBgColor(self.rootModule, item, item.level);
      }
      return self.getBgColor(self.rootModule, item, item.level);
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    setActiveItem(item) {
      this.$emit("callAssociations");
      let hasChild = item.child && item.child.data && item.child.data.length > 0;
      this.$store.dispatch('programmatic/propertyChangeable', !hasChild);
      this.$store.dispatch("programmatic/getActiveScenarioCollection", item.id).then(
          this.$store.dispatch("programmatic/getScenarioProperties", item.id)
      )
    },
    getAssociation(item) {
      const self = this;
      let hasChild = item.child && item.child.data && item.child.data.length > 0;
      // this.$store.dispatch("association/getItemAssociations", item.id);
      this.$emit("callAssociations");
      if(this.associatedOpt) {
        if(this.activeModuleId && parseInt(this.activeModuleId) !== parseInt(this.rootModule.id)) { // not same module id
          let activeModule = this.visualCollections.findIndex(module => parseInt(module.id) === parseInt(this.activeModuleId));
          if(activeModule >= 0 && this.visualCollections[activeModule + 1] && parseInt(this.visualCollections[activeModule + 1].id) === parseInt(this.rootModule.id)) { // if it is next module
            this.$store.dispatch("programmatic/addOrRemovesAssociation", {
              associated_id: item.id,
              associated_module_collection_id: item.module_collection_id,
              scenario_collection_id: self.lastClickedItem.id,
              module_collection_id: self.lastClickedItem.module_collection_id,
              project_id: self.$route.params.id,
              scenario_id: self.$route.params.scenario_id
            }).then(response => {
              if(response) {
                if(response.data) {
                  if(response.data.data.deleted_at) {
                    self.$emit("removeAssociationForApi", item.id);
                  } else {
                    self.$emit("toggleForApiAssociation", item);
                  }
                }
              }
            });
          } else {
            alert('Sorry! Item can not be associated with the module');
          }
        } else {
          if(!hasChild) {
            this.$emit("toggleForAssociation", item, hasChild, this.rootModule.id);
          }
        }
      } else {
        this.$emit("toggleForAssociation", item, hasChild, this.rootModule.id);
      }
    },
    pushIndirectAssociatedData(item) {
      let hasChild = item.child && item.child.data && item.child.data.length > 0;
      this.$emit("pushIndirectAssociatedData", item, hasChild);
    },
    getItemVisibility(item) {
      let existence = false;
      let ids = [];
      if(this.sortByAssociatedOpt) {
        ids.push(item.id);
        let childIds = this.getChildId(item);
        ids = ids.concat(childIds);
        existence = this.checkIdsExistence(ids);
        return existence;
      }
      return true;
    },
    getChildId(item) {
      let ids = [];
      item.child.forEach(child => {
        ids.push(child.id);
        let childIds = this.getChildId(child);
        ids = ids.concat(childIds);
      });
      return ids;
    },
    checkIdsExistence(ids) {
      let existence = false;
      ids.forEach(id => {
        if(!existence) {
          existence = this.itemExistenceCheck(id);
          if(existence) {
            existence = true;
          }
        }
      });
      return existence;
    },
    itemExistenceCheck(id) {
      let existence = this.assocChoosedItem.findIndex(assoc => parseInt(id) === parseInt(assoc.id));
      if(existence >= 0) {
        return true;
      } else {
        existence = this.associatedItems.findIndex(assoc => parseInt(assoc.associated_id) === parseInt(id));
        if(existence >= 0) {
          return true;
        } else {
          existence = this.indirectAssociatedData.findIndex(assoc => parseInt(assoc.id) === parseInt(id));
          return existence >= 0;
        }
      }
    },
    getTheWidth(moduleId) {
      let element = $("#prog_visual_module_" + moduleId);
      this.$emit('scrollToPosition', element[0].offsetLeft);
    },
    getModuleTitle() {
      return this.rootModule.title.length <= 14 ? this.rootModule.title : this.rootModule.title.substring(0, 9) + '...';
    },
    getData(items) {
      let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.rootModule.id);
      if(moduleInIsolation && moduleInIsolation.isolate) {
        return items.filter(data => moduleInIsolation.isolatedIds.includes(data.id));
      }
      return items;
    },
  }
}
</script>

<style scoped>
.ellipse-break {
  white-space: nowrap;
}
</style>
