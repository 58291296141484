<template>
  <div class="cntxt-menu fs-14 text-secondary-one shadow-two rounded bg-primary-three py-5 fixed fw-400 capitalize">
    <div class="flex justify-between items-center relative cursor-pointer mb-2 px-5">
      <span class="w-full h-full block fw-600">FOR ALL</span>
    </div>
    <div class="ct-li flex justify-between items-center relative cursor-pointer py-2 px-5">
      <span class="w-full h-full block">Appearance</span>
      <img src="/images/icons/build/arrow_side_inactive.svg" class="ml-5" />
      <div class="cntxt-menu cntxt-submenu shadow-two rounded bg-primary-three p-5 z-10 hidden absolute">
        <span class="ct-li flex justify-between items-center cursor-pointer i-mb-15" @click="$emit('appearance', 0)">Default</span>
        <span class="ct-li flex justify-between items-center cursor-pointer i-mb-15" @click="$emit('appearance', 1)">Outline Only</span>
        <span class="ct-li flex justify-between items-center cursor-pointer" @click="$emit('appearance', 2)">Text Only</span>
      </div>
    </div>
    <div class="ct-li flex justify-between items-center relative cursor-pointer py-2 px-5" v-if="menu.description">
      <span class="w-full h-full block">Description</span>
      <img src="/images/icons/build/arrow_side_inactive.svg" class="ml-5" />
      <div class="cntxt-menu cntxt-submenu shadow-two rounded bg-primary-three p-5 z-10 hidden absolute">
        <span class="ct-li flex justify-between items-center cursor-pointer i-mb-15" @click="$emit('description', true)">Show</span>
        <span class="ct-li flex justify-between items-center cursor-pointer" @click="$emit('description', false)">Hide</span>
      </div>
    </div>
    <div class="ct-li flex justify-between items-center relative cursor-pointer py-2 px-5">
      <span class="w-full h-full block">Index</span>
      <img src="/images/icons/build/arrow_side_inactive.svg" class="ml-5" />
      <div class="cntxt-menu cntxt-submenu shadow-two rounded bg-primary-three p-5 z-10 hidden absolute">
        <span class="ct-li flex justify-between items-center cursor-pointer i-mb-15" @click="$emit('indexTitle', true)">Show</span>
        <span class="ct-li flex justify-between items-center cursor-pointer" @click="$emit('indexTitle', false)">Hide</span>
      </div>
    </div>
    <div class="mt-2 mb-4 bg-secondary-two mx-5" style="height: 1px"></div>
    <div class="flex justify-between items-center relative cursor-pointer mb-2 px-5">
      <span class="w-full h-full block fw-600">For Individual</span>
    </div>
    <div class="ct-li flex justify-between items-center relative cursor-pointer py-2 px-5">
      <span class="w-full h-full block">Appearance</span>
      <img src="/images/icons/build/arrow_side_inactive.svg" class="ml-5"/>
      <div class="cntxt-menu cntxt-submenu shadow-two rounded bg-primary-three p-5 z-10 hidden absolute">
        <span class="ct-li flex justify-between items-center cursor-pointer i-mb-15" @click="$emit('appearanceSelf', 0)">Default</span>
        <span class="ct-li flex justify-between items-center cursor-pointer" :class="{'i-mb-15' : viewIndex == 1}" @click="$emit('appearanceSelf', 1)">Outline Only</span>
        <span class="ct-li flex justify-between items-center cursor-pointer" @click="$emit('appearanceSelf', 2)" v-show="viewIndex == 1">Text Only</span>
      </div>
    </div>
    <div v-if="canEdit(project.cans)" class="ct-li flex justify-between items-center relative cursor-pointer px-5 pt-2 pb-4">
      <span class="w-full h-full block">Color</span>
      <img src="/images/icons/build/arrow_side_inactive.svg" class="ml-5" />
      <div class="cntxt-menu cntxt-submenu shadow-two rounded bg-primary-three p-5 z-10 hidden absolute">
        <span class="ct-li flex justify-between items-center cursor-pointer i-mb-15" @click="$emit('background', null)">Default</span>
        <span class="ct-li flex justify-between items-center cursor-pointer i-mb-15" @click="$emit('background', '#0087CF')">Blue</span>
        <span class="ct-li flex justify-between items-center cursor-pointer i-mb-15" @click="$emit('background', '#57C863')">Green</span>
        <span class="ct-li flex justify-between items-center cursor-pointer i-mb-15" @click="$emit('background', '#CD0CD1')">Magenta</span>
        <span class="ct-li flex justify-between items-center cursor-pointer i-mb-15" @click="$emit('background', '#F0BA50')">Yellow</span>
        <span class="ct-li flex justify-between items-center cursor-pointer" @click="$emit('background', '#57C3CA')">Ocean Blue</span>
      </div>
    </div>
    <div v-if="canCreate(project.cans)" class="ct-li flex justify-between items-center relative i-mb-15 px-5">
        <span class="w-full h-full block"
          :class="item && item.level == 4 ? 'ct-li-inactive cursor-not-allowed' : 'cursor-pointer'"
          @click="item && item.level == 4 ? $event.stopPropagation() : $emit('addNew', 2)">
          Add sub item
        </span>
    </div>
<!--    <div class="ct-li flex justify-between items-center relative cursor-pointer i-mb-15 px-5">-->
<!--      <span class="w-full h-full block">Edit</span>-->
<!--    </div>-->
    <div v-if="canEdit(project.cans) && menu.isolation"
         @click="$emit('saveIsolation')"
         class="ct-li flex justify-between items-center relative cursor-pointer px-5 i-mb-15">
      <span class="w-full h-full block">Save Isolation</span>
    </div>
    <div v-if="canEdit(project.cans) && menu.remove_isolation"
         @click="$emit('removeIsolation')"
         class="ct-li flex justify-between items-center relative cursor-pointer px-5 i-mb-15">
      <span class="w-full h-full block">Remove Isolation</span>
    </div>
    <div v-if="canDelete(project.cans)" @click="$emit('deleteLevelItem')" class="ct-li flex justify-between items-center relative cursor-pointer px-5">
      <span class="w-full h-full block">Delete</span>
    </div>
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canDelete, canCreate, canEdit } = usePermission()
</script>

<script>
import { mapGetters} from "vuex";
export default {
  data() {
    return {
      //
    };
  },
  props: {
    item: Object,
    viewIndex: {
      default: 1
    },
    menu: {
      description: true,
      isolation: false,
      remove_isolation: false
    },
  },
  methods: {

  },
  computed: {
    ...mapGetters({
      project: 'project/project'
    }),
  },
};
</script>
<style>
.cntxt-menu {
  width: 185px;
  z-index: 9999999;
}
.cntxt-menu .ct-li {
  opacity: .6;
  transition: background-color 0.2s ease-in;
}
.cntxt-menu .ct-li:hover {
  opacity: 1;
}
.cntxt-submenu {
  top: 0;
  left: 185px;
}
.ct-li:hover .cntxt-submenu {
  display: block;
}
</style>
