<template>
  <div>
    <div class="relative i-border-1 border-secondary-two rounded w-full i-mt-30 i-h-50">
      <input
          type="text"
          class="focus:outline-none py-2 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two i-h-50"
          placeholder="Search"
      />
    </div>

    <div class="mt-8 bld-navigation overflow-y-auto">
      <div class="flex flex-col pl-10 pr-10" v-for="(sModule, index) in modules" :key="index" :class="{'mt-8': index > 0}">
        <div class="relative flex flex-row shadow-one i-h-50 w-full rounded">
          <div
              class="flex items-center bld-level-w i-h-50 bg-primary-three hover:bg-secondary-five cursor-pointer relative pr-item rounded w-full"
              :class="{'shadow-two hover:bg-rgb-primary-four': sModule.id === selectedModule}"
              :style="{'background': selectedModule === sModule.id ? getBg(sModule) : ''}" @dblclick="toggleModuleIsolation(sModule.id)">
            <div class="flex items-center pr-left-content w-full" @click="toggleModuleSelection(sModule.id, index)">
              <span class="font-poppins fw-600 text-primary-one fs-14 px-4 capitalize ellipse prg-lft-title"
                    :class="{'text-primary-three': sModule.id === selectedModule}">{{ sModule.title }}</span>
            </div>
            <div
                class="absolute pr-hover-div w-10 i-h-50 cursor-pointer items-center justify-center rounded-br rounded-tr"
                @click="toggleExpand(sModule.id, index)">
              <img src="/images/icons/chevron_down_black.svg" alt="icon" class=""
                   :class="{'rotate-180': sModule.expanded}">
            </div>
          </div>
        </div>

        <scenario-level-collection :module="sModule"
                                   :collections="sModule.scenario_collections"
                                   :assessmentScreen="assessmentScreen"
                                   :connectionSelection="connectionSelection"
                                   :isolatedModules="isolatedModules"
                                   v-show="sModule.expanded"
                                   @toggleIsolation="toggleIsolation" />
      </div>
      <div class="pl-10 pr-10">
        <div @click="addModule()" class="fs-14 fw-600 text-primary-one bg-primary-three pl-3 rounded i-h-50 shadow-two flex w-full items-center text-left mt-8 cursor-pointer hover:bg-secondary-five">
          + Add Module
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ScenarioLevelCollection from "./ScenarioLevelCollection";

export default {
  name: "ScenarioModuleList",
  components: {ScenarioLevelCollection},
  props: ['assessmentScreen', 'connectionSelection', 'isolatedModules'],
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      modules: "programmatic/scenarioCollections",
      selectedModule: "programmatic/selectedModule",
      colorCodes: "global/colorCodes",
      projectData: "project/project",
    })
  },
  methods: {
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    toggleModuleSelection(id, index) {
      this.$store.dispatch("programmatic/toggleModuleSelection", id);
      this.$emit("toggleModuleSelection", id, index);
      this.$store.dispatch("workspace/updateProjectData", {
        id: this.projectData.id,
        active_module_id: id,
        workspace_id: this.projectData.workspace_id,
        name: this.projectData.name
      });
    },
    toggleExpand(id, index) {
      this.modules[index].expanded = !this.modules[index].expanded;
    },
    getBg(module) {
      let collectionSettings = JSON.parse(module.collection_settings);
      if(collectionSettings.collections[0].custom_bg) {
        return collectionSettings.collections[0].custom_bg;
      }
      let colorCodes = this.getLevelWiseColorCode(1);
      return colorCodes[collectionSettings.collections[0].color];
    },
    toggleIsolation(item) {
      this.$emit("toggleIsolation", item);
    },
    removeIsolation(moduleId) {
      this.$emit("removeIsolation", moduleId);
    },
    toggleModuleIsolation(moduleId) {
      this.$emit("toggleModuleIsolation", moduleId);
    },
    addModule() {
      this.$Progress.start();
      this.$store.dispatch("programmatic/addModule", {
        project_id: this.$route.params.id
      }).then(response => {
        this.$Progress.finish();
        this.$store.dispatch("programmatic/loadScenarioCollection", {
          project_id: this.$route.params.id,
          scenario_id: this.$route.params.scenario_id,
          is_return: true
        }).then(res => {
          if(res.data.data) {
            let fullData = res.data.data;
            let obj = fullData[fullData.length - 1];
            if(obj) {
              obj.expandedLevel = 4;
            }
            this.$store.state.programmatic.scenarioCollections.push(obj);
          }
        })
      });
    }
  }
}
</script>

<style scoped>

</style>
