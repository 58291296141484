<template>
  <div class="flex" v-if="item.level === 4 || item.child.length <= 0">
    <div :class="getEmptySpaceClass(item)" v-if="item.child.length <= 0"></div>
    <h2 class="fs-14 font-poppins text-secondary-one opacity-60 pr-visual-item-title i-ml-25 cursor-pointer text-left" :class="{'opacity-100': isHighlighted()}">{{ getTitle(item.level, index, item.title) }}</h2>
    <div class="flex items-center visual-prop-root-box"
         v-for="(countItem, countIndex) in countChild"
         :key="countIndex"
         :class="{'visual-property-boxes': countIndex === 0, 'ml-5': countIndex > 0}">
      <div class="flex">
        <div class="visual-property-box"
             v-for="(i, pIndex) in 5"
             :key="pIndex"
             :style="{'margin-left': i > 1 ? '4px' : '0'}"
             :class="getPropertyClassValue(pIndex, countIndex)">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Property",
  props: ['item', 'rootModule', 'index', 'countChild', 'rootProperty', 'selectedChildProperties', 'associatedItems', 'indirectAssociatedData', 'associatedOpt', 'assocChoosedItem'],
  data() {
    return {

    }
  },
  watch: {
    associatedItems: function(val) {
      const self = this;
      if(val.length > 0 && !this.associatedOpt) {
        let itemExistence = self.indirectAssociatedData.find(item => parseInt(item.id) === parseInt(self.item.id));
        if(!itemExistence) {
          self.associatedItems.forEach(assocItem => {
            if(parseInt(self.item.id) === parseInt(assocItem.associated_id)) {
              self.$emit("pushIndirectAssociatedData", self.item);
            }
          });
        }
      }
    }
  },
  methods: {
    getEmptySpaceClass(item) {
      return 'l' + item.level + '-no-child';
    },
    getPropertyClassValue(index, countIndex) {
      if(this.item.properties.length > 0) {
        let targetProperty = this.item.properties.find(item => parseInt(item.property_collection_id) === parseInt(this.selectedChildProperties[countIndex]));
        if(targetProperty) {
          let value = targetProperty.sample_value;
          value = value / 2;
          let classValue = 0;
          if(value > index) {
            classValue = value;
          }
          let className = 'prop-value-' + Math.ceil(classValue);
          if(this.isHighlighted()) {
            return className + '-selected';
          }
          return className;
        }
        return null;
      }
      return null;
    },
    getTitle(parentCodeIndex, index, title) {
      let root = false;
      if(this.item.level === 1) {
        root = true;
      }

      let nextNumber = index + 1;
      let returnTitle = '';
      if(this.rootModule.collection_settings) {
        let moduleSettings = JSON.parse(this.rootModule.collection_settings);
        if (moduleSettings && moduleSettings.collections) {
          if (moduleSettings.index_id) {
            returnTitle = moduleSettings.collections[parentCodeIndex].index_id;
          }
          if (moduleSettings.index_number) {
            if (root) {
              let number = parseInt(moduleSettings.collections[parentCodeIndex].index_number) + parseInt(index);
              returnTitle = returnTitle + number;
            } else {
              returnTitle = returnTitle + moduleSettings.collections[parentCodeIndex - 1].index_number + '.' + nextNumber;
            }
          }
          return returnTitle + ' ' + title;
        } else {
          return title;
        }
      } else {
        return title;
      }
    },
    isHighlighted() {
      const self = this;
      if(self.assocChoosedItem && self.assocChoosedItem.length > 0) {
        let checkExistence = self.associatedItems.findIndex(assocItem => parseInt(assocItem.associated_id) === parseInt(self.item.id));
        if(checkExistence >= 0) {
          return true;
        }
        let itemIndirectData = self.indirectAssociatedData.findIndex(inDirItem => parseInt(inDirItem.id) === parseInt(self.item.id));
        if(itemIndirectData >= 0) {
          return true;
        }
        let itemExistence = self.assocChoosedItem.find(assocItem => parseInt(assocItem.id) === parseInt(self.item.id));
        return !!itemExistence;
      }
      return false;
    }
  }
}
</script>

<style scoped>
.pr-visual-item-title {
  width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pr-visual-item-title:hover {
  opacity: 1;
}
.prop-value-1-selected {
  background: #3D3D3D !important;
  opacity: 1;
}
.prop-value-2-selected {
  background: #3D3D3D !important;
  opacity: 1;
}
.prop-value-3-selected {
  background: #2B80AD !important;
  opacity: 1;
}
.prop-value-4-selected {
  background: #2B80AD !important;
  opacity: 1;
}
.prop-value-5-selected {
  background: #2B80AD !important;
  opacity: 1;
}
.pr-visual-item-title:hover ~ .visual-prop-root-box .visual-property-box, .visual-prop-root-box:hover .visual-property-box {
  opacity: 1;
}
.visual-property-boxes {
  margin-left: 40px;
}
.visual-property-box {
  width: 8px;
  height: 9px;
  background: #C9C9C9;
}
.l1-no-child {
  height: 20px;
  width: 87px;
}
.l2-no-child {
  height: 20px;
  width: 58px;
}
.l3-no-child {
  height: 20px;
  width: 28px;
}
.prop-value-0 {
  background: #C9C9C9;
  opacity: 0.6;
}
.prop-value-1 {
  background: #3D3D3D;
  opacity: 0.6;
}
.prop-value-2 {
  background: #3D3D3D;
  opacity: 0.6;
}
.prop-value-3 {
  background: #2B80AD;
  opacity: 0.6;
}
.prop-value-4 {
  background: #2B80AD;
  opacity: 0.6;
}
.prop-value-5 {
  background: #2B80AD;
  opacity: 0.6;
}
</style>
