<template>
  <div>
    <div class="flex mt-9">
      <img src="/images/icons/build/navigation.svg" alt="icon"
           @click="toggleSelectedOption('list'), manageListClickCount()"
           class="i-menu-icon cursor-pointer opacity-50 hover:bg-secondary-three"
           :class="{'opacity-100': selectedOption === 'list'}" />
      <img src="/images/icons/build/global_library.svg" alt="icon"
           @click="toggleSelectedOption('library')"
           class="i-menu-icon cursor-pointer opacity-50 i-ml-30 hover:bg-secondary-three"
           :class="{'opacity-100': selectedOption === 'library'}" />
      <img src="/images/icons/build/properties.svg" alt="icon" @click="toggleSelectedOption('property')"
           class="i-menu-icon cursor-pointer opacity-50 i-ml-30 hover:bg-secondary-three"
           :class="{'opacity-100': selectedOption === 'property'}" />
      <img src="/images/icons/programmatic/visualizer.svg" alt="icon" @click="toggleVisualizer()"
           class="i-menu-icon cursor-pointer opacity-50 i-ml-30 hover:bg-secondary-three"
           :class="{'opacity-100': visualizer}" />

      <img src="/images/icons/brainstorm/TagIcon.svg" alt="icon" @click="toggleSelectedOption('tagList')"
           class="i-menu-icon w-6 cursor-pointer opacity-50 i-ml-30 hover:bg-secondary-three"
           :class="{'opacity-100': selectedOption === 'tagList'}" />

    </div>
    <scenario-module-list
      v-if="selectedOption === 'list'"
      :assessmentScreen="assessmentScreen"
      :connectionSelection="connectionSelection"
      :isolatedModules="isolatedModules"
      @toggleModuleSelection="toggleModuleSelection"
      @toggleIsolation="toggleIsolation"
      @toggleModuleIsolation="toggleModuleIsolation" />
    <module-list
      v-if="selectedOption === 'b-list'"
        :assessmentScreen="assessmentScreen"
        :connectionSelection="connectionSelection"
        @toggleModuleSelection="toggleModuleSelection"
        @updateForceKeys="updateForceKeys" />
    <global-list
      v-if="selectedOption === 'library'" :assessmentScreen="assessmentScreen"
      :connectionSelection="connectionSelection" @pushToIsolation="pushToIsolation" />
    <property
      v-if="selectedOption === 'property'"
      :assessmentScreen="assessmentScreen"
      :connectionSelection="connectionSelection"
      :hasAssessment="hasAssessment"
    />
    <attached-tags class="overflow-auto mt-10" style="height: 845px" v-if="selectedOption === 'tagList'" />
  </div>
</template>

<script>
import GlobalList from "./GlobalList";
import Property from "./Property";
import ScenarioModuleList from "./ScenarioModuleList";

export default {
  name: "LeftParent",
  props: ['hasAssessment', 'assessmentScreen', 'connectionSelection', 'isolatedModules'],
  components: {ScenarioModuleList, Property, GlobalList},
  created() {
    const self = this;
    self.$store.dispatch("project/loadBuildGlobalProjects", this.$route.params.id);
  },
  data() {
    return {
      selectedOption: 'list',
      visualizer: false
    }
  },
  methods: {
    toggleSelectedOption(value) {
      this.selectedOption = value
    },
    toggleModuleSelection(id, index) {
      this.$emit("toggleModuleSelection", id, index);
    },
    toggleVisualizer() {
      this.visualizer = !this.visualizer;
      this.$emit("toggleVisualizer", this.visualizer);
    },
    manageListClickCount() {
      this.$emit("manageListClickCount");
    },
    toggleIsolation(item) {
      this.$emit("toggleIsolation", item);
    },
    updateForceKeys() {
      this.$emit('updateForceKeys');
    },
    removeIsolation(moduleId) {
      this.$emit("removeIsolation", moduleId);
    },
    toggleModuleIsolation(moduleId) {
      this.$emit("toggleModuleIsolation", moduleId);
    },
    pushToIsolation(payload) {
      this.$emit("pushToIsolation", payload);
    }
  }
}
</script>

<style scoped>

</style>
