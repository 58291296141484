<template>
<div class="content panzoom-exclude">
  <div :id="id" :se-min="minThreshold" :se-step="step" :se-min-value="min" :se-max-value="max" :se-max="maxThreshold" class="pr-slider">
    <div class="slider-touch-left">
      <span></span>
    </div>
    <div class="slider-touch-right">
      <span></span>
    </div>
    <div class="slider-line">
      <span></span>
    </div>
  </div>
</div>
</template>

<script>
import ZbRangeSlider from './ZbRangeSlider'
export default {
  props: {
    id: {
      type: [String, Number],
    },
    minThreshold: {
      // type: Number,
      default: 0
    },
    maxThreshold: {
      // type: Number,
      default: 10
    },
    step: {
      type: Number,
      default: 1
    },
    min: {
      // type: Number,
      required: true
    },
    max: {
      // type: Number,
      required: true
    }
  },
  // props: ['minThreshold', 'maxThreshold', 'step', 'min', 'max'],
  data: function () {
    return {
      instance: undefined
    }
  },
  mounted: function () {
    this.instance = new ZbRangeSlider(this.id)
    this.instance.onChange = (min, max) => this.updateValues(min, max)
  },
  destroyed: function () {
  },
  methods: {
    updateValues: function (min, max) {
      // console.log(min, max);
      this.$emit('update:min', parseInt(min))
      this.$emit('update:max', parseInt(max))
      this.$emit('resetMode')
    }
  }
}
</script>

<style>
.pr-slider {
  display: block;
  position: relative;
  /* height: 36px; */
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  background: transparent !important;
}
.pr-slider .slider-touch-left,
.pr-slider .slider-touch-right {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: absolute;
  /* height: 20px;
  width: 10px;
  padding: 6px; */
  z-index: 2;
  top: calc(-10% - 0.25rem);
}
.pr-slider .slider-touch-left span,
.pr-slider .slider-touch-right span {
  display: block;
  /* width: 100%; */
  /* height: 100%; */
  width: 20px;
  height: 10px;
  background: #F9F9F9;
  /* border: 1px solid #a4a4a4; */
  /* border-radius: 50%; */
}
.pr-slider .slider-line {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  /* width: calc(100% - 36px);
  left: 18px;
  top: 16px;
  height: 4px; */
  width: 100%;
  left: 0;
  top: 0;
  height: 2.5px;
  border-radius: 4px;
  background: #C9C9C9;
  z-index: 0;
  overflow: hidden;
}
.pr-slider .slider-line span {
  display: block;
  height: 100%;
  width: 0%;
  background: #F9F9F9;
}
</style>
