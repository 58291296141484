<template>
  <div >
    <div class="flex">
      <module-data v-for="(rootModule, index) in getModules"
                   :key="index"
                   :rootModule="rootModule"
                   :index="index"
                   :assocChoosedItem="assocChoosedItem"
                   :activeModuleId="lastItemModuleId"
                   :associatedItems="associatedItems"
                   :indirectAssociatedData="indirectAssociatedData"
                   :sortByAssociatedOpt="sortByAssociatedOpt"
                   :associatedOpt="associatedOpt"
                   :lastClickedItem="lastClickedItem"
                   :isolatedModules="isolatedModules"
                   @toggleForAssociation="toggleForAssociation"
                   @pushIndirectAssociatedData="pushIndirectAssociatedData"
                   @removeAssociationForApi="removeAssociationForApi"
                   @toggleForApiAssociation="toggleForApiAssociation"
                   @scrollToPosition="scrollToPosition"
                   @callAssociations="callAssociations"
                   :ref="'prog_module_data_' + rootModule.id" />
    </div>
    <div class="visual-fake-div"></div>
  </div>
</template>

<script>
import $ from 'jquery';
import {mapGetters} from 'vuex';
import ModuleData from "../../../components/programmatic/visualization/ModuleData";
export default {
  name: "AssessmentScreen",
  components: {ModuleData},
  props: ['full_screen', 'isolatedModules', 'isolatedModuleId'],
  created() {
    const self = this;
    // self.$store.dispatch("programmatic/getScenarioVisualData", {
    //   project_id: self.$route.params.id,
    //   scenario_id: self.$route.params.scenario_id
    // });
  },
  data() {
    return {
      scenario_selected: this.$route.params.scenario_id,
      associatedOpt: false,
      sortByAssociatedOpt: false,
      moduleProp: [],
      assocChoosedItem: [],
      lastItemModuleId: null,
      associatedItems: [],
      indirectAssociatedData: [],
      lastClickedItem: null,
      clickedOriginalData: [],
      serverMode: process.env.NODE_ENV
    }
  },
  computed: {
    ...mapGetters({
      scenarios: 'programmatic/scenarios',
      visualCollections: "programmatic/scenarioCollections",
      colorCodes: "global/colorCodes"
    }),
    getModules() {
      if(this.visualCollections && this.visualCollections.length > 0) {
        if(this.isolatedModuleId) {
          return this.visualCollections.filter(data => data.id === this.isolatedModuleId);
        }
        return this.visualCollections;
      }
      return [];
    }
  },
  watch: {
    assocChoosedItem: function(val) {
      if(this.assocChoosedItem.length <= 0) {
        this.lastItemModuleId = null;
        this.lastClickedItem = null;
      } else {
        this.$store.dispatch("association/getItemAssociations", this.assocChoosedItem[this.assocChoosedItem.length - 1].id);
      }
      this.$emit("syncAssocChoosedItem", val);
    }
  },
  methods: {
    getSelectionScenarioCollections() {
      let projectId = parseInt(this.$route.params.id);
      let scenarioId = parseInt(this.scenario_selected);
      this.$router.push({ name: 'programmatic.scenario.view', params: { id: projectId, scenario_id: scenarioId }});
      this.$store.dispatch("programmatic/loadScenarioCollection", {
        project_id: projectId,
        scenario_id: scenarioId
      });
    },
    toggleAssessmentScreen() {
      this.$emit("toggleAssessmentScreen", false);
    },
    toggleAssociatedOpt() {
      this.associatedOpt = !this.associatedOpt;
      this.indirectAssociatedData = [];
      this.assocChoosedItem = [];
      this.associatedItems = [];
      this.indirectAssociatedData = [];
      if(this.associatedOpt) {
        this.sortByAssociatedOpt = false;
        this.emptyForAssociation();
      } else {
        this.setForAssocChoosedItem();
      }

      this.$emit("toggleSortByAssociatedOpt", this.associatedOpt, this.sortByAssociatedOpt);
    },
    toggleSortByAssociatedOpt() {
      this.sortByAssociatedOpt = !this.sortByAssociatedOpt;
      if(this.sortByAssociatedOpt) {
        this.associatedOpt = false;
      }
      this.$emit("toggleSortByAssociatedOpt", this.associatedOpt, this.sortByAssociatedOpt);
    },
    toggleFullScreen() {
      this.$emit('toggleFullScreen');
    },
    setForAssocChoosedItem() {
      this.assocChoosedItem = [];
      this.clickedOriginalData.forEach(item => {
        this.assocChoosedItem.push(item);
        this.associatedItems = this.associatedItems.concat(item.associates);
      });
    },
    toggleForAssociation(item, hasChild, moduleId, operation = false) {
      if(this.associatedOpt && parseInt(moduleId) === parseInt(this.lastItemModuleId)) {
        this.emptyForAssociation();
      } else {
        if(!this.associatedOpt) {
          if((this.lastItemModuleId && parseInt(this.lastItemModuleId) !== parseInt(moduleId))) {
            this.emptyForAssociation();
          }
        }
      }

      if(!this.associatedOpt) {
        this.assocChoosedItem.push(item);
        this.clickedOriginalData.push(item);
      } else {
        if(!this.lastItemModuleId || parseInt(moduleId) === parseInt(this.lastItemModuleId)) {
          this.assocChoosedItem.push(item);
          this.clickedOriginalData.push(item);
        }
      }

      if(this.associatedOpt) {
        if(!this.lastItemModuleId) {
          this.lastItemModuleId = moduleId;
          this.lastClickedItem = item;
        }
      } else {
        this.lastItemModuleId = moduleId;
        this.lastClickedItem = item;
      }
      if(!operation) {
        this.pushItemAssociation(item.id, item.associates);
      }
      if(hasChild && !operation) {
        this.pushChildItem(item)
      }
    },
    pushChildItem(item) {
      const self = this;
      item.child.data.forEach(childItem => {
        let itemExist = self.assocChoosedItem.find(assoc => parseInt(assoc.id) === parseInt(childItem.id));
        if(!itemExist) {
          self.assocChoosedItem.push(childItem);
          self.pushChildItem(childItem);
          self.pushItemAssociation(childItem.id, childItem.associates);
        }
      });
    },
    pushItemAssociation(itemId, associations) {
      const self = this;
      associations.forEach(item => {
        self.associatedItems.push(item);
      });
    },
    pushIndirectAssociatedData(item, hasChild) {
      const self = this;
      self.indirectAssociatedData.push(item);
      self.pushItemAssociation(item.id, item.associates);
      if(hasChild) {
        self.pushIndirectAssociatedChildData(item);
      }
    },
    pushIndirectAssociatedChildData(item) {
      const self = this;
      item.child.data.forEach(child => {
        self.indirectAssociatedData.push(child);
        self.pushItemAssociation(child.id, child.associates);
        self.pushIndirectAssociatedChildData(child);
      });
    },
    resetAll() {
      this.sortByAssociatedOpt = false;
      this.lastClickedItem = null;
      this.lastItemModuleId = null;
      this.emptyForAssociation();
      this.$emit("resetAll");
    },
    emptyForAssociation() {
      this.assocChoosedItem = [];
      this.associatedItems = [];
      this.indirectAssociatedData = [];
      this.clickedOriginalData = [];
    },
    toggleForApiAssociation(item) {
      this.associatedItems.push({
        scenario_collection_id: this.assocChoosedItem[0].id,
        module_collection_id: this.assocChoosedItem[0].module_collection_id,
        associated_id: item.id,
        associated_module_collection_id: item.module_collection_id
      });
    },
    removeAssociationForApi(id) {
      let index = this.associatedItems.findIndex(item => parseInt(item.associated_id) === parseInt(id));
      if(index >= 0) {
        this.associatedItems.splice(index, 1);
      }
    },
    scrollToModule(moduleId) {
      this.$refs[`prog_module_data_${moduleId}`][0].getTheWidth(moduleId);
    },
    scrollToPosition(offsetWidth) {
      $('#prog-visual-root-box').animate({ scrollLeft: offsetWidth - 432 }, 800);
    },
    callAssociations() {
      if(this.assocChoosedItem.length > 0) {
        this.$store.dispatch("association/getItemAssociations", this.assocChoosedItem[this.assocChoosedItem.length - 1].id);
      }
    }
  }
}
</script>

<style scoped>
.prog-assessment-screen {
  background: #1B1B1D;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  height: 1000px;
}
.prog-visual-regular-screen {
  width: 1496px;
  margin-left: 9px;
}
.prog-visual-full-screen {
  width: 1840px;
  margin-left: -3px;
}
.scenario-select {
  margin-left: 15px;
}
.prog-present-btn {
  border: none;
  outline: none;
}
.prog-assess-top-link {
  margin-left: 40px;
}
.prog-present-btn:focus {
  border: none;
  outline: none;
}
.library-select {
  border: none;
}
.prog-assess-pd {
  padding-right: 20px;
}

.pr-ver-divider {
  width: 2px;
  height: 10px;
}
.visual-root-box {
  overflow-x: auto;
}
.visual-root-box-mt {
  /*margin-top: 32px;*/
}
.visual-regular-root-box {
  width: 1480px;
}
.visual-fullscreen-root-box {
  width: 1790px;
}
.prog-module-initial-box-ml {
  margin-left: 20px;
}
.prog-full-screen {
  width: 20px;
  height: 20px;
}
.prog-full-screen:hover {
  opacity: 1;
}
.connection-icon {
  padding: 1px;
}
.visual-fake-div {
  width: 1445px;
  margin-left: 1445px;
}
.prog-default-scen-box {
  padding: 4px;
}
</style>
