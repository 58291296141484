<template>
  <div class="-ml-1" :class="{'prog-right-part-fullscreen': full_screen}">
    <div v-if="!cardFullScreen" class="flex"
         :class="{'pl-root-visibility': !parentVisibility}">
      <div class="flex relative pr-default-screen-container" id="prog-section-container">
        <div class="flex prog-single-section" v-for="(item, index) in getModules"
          :key="item.id"
          :id="'linked-module-'+item.id"
        >
          <div class="pr-long-bar bg-primary-three shadow-one rounded relative mr-10">
            <div class="flex flex-col items-center justify-center" @click="changeLevel(item, index)">
              <div class="cursor-pointer i-mt-25">
                <div class="pr-bar-level-box"
                  :class="{'bg-secondary-one': item.expandedLevel >= 1}"></div>
                <div class="pr-bar-level-box"
                  :class="{'bg-secondary-one': item.expandedLevel >= 2}"></div>
                <div class="pr-bar-level-box"
                  :class="{'bg-secondary-one': item.expandedLevel >= 3}"></div>
                <div class="pr-bar-level-box"
                  :class="{'bg-secondary-one': item.expandedLevel >= 4}"></div>
              </div>
            </div>
            <img src="/images/icons/programmatic/zoom.svg" alt="image"
                 class="cursor-pointer opacity-40 hover:bg-primary-three pr-icon mt-5 px-3"
                 @click="toggleZoom(item.id, index)"
                 :class="{'opacity-100': zoomModules[index].viewerMode > 1}">
            <div 
              class="flex assessment-selection transform -rotate-90 relative" 
              @click="toggleAssessmentMenu(index, item)"
            >
              <p v-if="item.assessments" class="cursor-pointer fs-14 font-poppins nowrap mr-2 opacity-60">
                {{ (item.assessments) ? item.assessments.find(el => el.selected == true)?.title : 'Properties' }}
              </p>
              <p v-else class="cursor-pointer fs-14 font-poppins nowrap mr-2 opacity-60">
                {{ assessments.find(el => el.selected == true).title }}
              </p>
              <img src="/images/icons/programmatic/chevron_down.svg" alt="icon" class="">
            </div>

            <!-- -->
            <div class="assessment-menu absolute flex flex-col rounded shadow-two overflow-y-auto" v-show="assessmentMenu == index && showMenu" >
              <span v-for="(assessmentItem, assessmentIndex) in (item.assessments) ? item.assessments : [] "
                    :key="assessmentIndex"
                    :value="assessmentItem.id"
                    @click="changeAssessment(index, item, assessmentIndex)"
                    class="fs-14 font-poppins cursor-pointer block text-left text-secondary-one bg-primary-three hover:bg-secondary-four">
                  {{ assessmentItem.title }}
              </span>
            </div>
            <div class="pr-long-title-div cursor-pointer" @click="changeLevel(item, index)">
              <span class="truncate text-left items-center fs-14 font-poppins absolute text-secondary-one pr-bar-title transform -rotate-90 bg-primary-three">{{ getIndexTitle(0, index, item.id) }} {{ item.title }}</span>
            </div>
          </div>
        <l-child
            :filteredCollections="filteredCollections"
            :module_id="item.id"
            :selectedConnection="selectedConnection"
            :parentModule="item"
            :isolatedIds="isolatedIds"
            :isolate="isolate"
            :isolatedItemId="isolatedItemId"
            :ref="'module_' + item.id"
            :mIndex="index"
            :isolatedModules="isolatedModules"
            @resetConn="selectedConnection = 1"
            @activePropertyOption="activePropertyOption"
            @zoomedFromChild="zoomedFromChild"
            @changeProp="selectedConnection = 1"
            @updateForceKeys="updateForceKeys"
            @pushToIsolation="pushToIsolation"
            @setDataSourceType="setDataSourceType"
            :key="forceKeys[index]"
            :rootIndex="getIndexTitle(0, index, item.id)"
            :title="getTitle(index, item)"
        />
        </div>
        <div class="fake-div"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import $ from "jquery";
$("input[type=range]").css("pointer-events", "none");
import LChild from "../../../components/programmatic/LChild";
import useAssessment from "@/views/screens/programmatic/useAssessment.js";


export default {
  setup(){
  const { getAssessment, getSystemDataWithProperties } = useAssessment()
    return {
      getAssessment,
      getSystemDataWithProperties
    }
  },
  name: "DefaultScreen",
    components: {
        LChild
    },
  props: ['filteredCollections', 'selectedModule', 'selectedModuleIndex', 'full_screen', 'isolatedIds', 'isolate', 'isolatedItemId', 'isolatedModules', 'isolatedModuleId'],
  created() {
    const self = this;
    // self.$store.dispatch("programmatic/loadScenarioCollection", {
    //   project_id: self.$route.params.id,
    //   scenario_id: self.$route.params.scenario_id
    // });
  },
  data() {
    return {
      forceKeys: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      scenario_selected: this.$route.params.scenario_id,
      assessmentScreen: false,
      cardFullScreen: false,
      parentVisibility: true,
      split: false,
      selectedConnection: 1,
      selectedParent: 0,
      activeArray: [1, 2, 3],
      renderLevelComponent: true,
      timelines: [
        {level: 1, display: false},
        {level: 2, display: false},
        {level: 3, display: false},
        {level: 4, display: false},
      ],
      assessments: [
        {id: 1, title: 'Properties', selected: true, }
      ],
      activeTimeline: [],
      activeCards: [],
      maximize: {
        state: false,
        index: ''
      },
      filterSplit: false,
      splitSubModule: '',
      cardView: false,
      cardFullScreenData: {},
      timelineOpt: {
        clicked: false,
        value: 'timeline'
      },
      childItemSelection: '',
      assessmentMenu: null,
      showMenu: false,
      zoomModules: [
        // { viewerMode: 1 },
        // { viewerMode: 1 },
        // { viewerMode: 1 },
        // { viewerMode: 1 },
        // { viewerMode: 1 },
        // { viewerMode: 1 },
        // { viewerMode: 1 },
        // { viewerMode: 1 },
        // { viewerMode: 1 },
      ]
    }
  },
  computed: {
    ...mapGetters({
      modules: "programmatic/projectModules",
      scenarioCollections: "programmatic/scenarioCollections",
      selectedFilterProperties: "programmatic/selectedFilterProperties",
      moduleLevel: 'GET_CURRENT_MODULE_LEVEL',
      scenarios: 'programmatic/scenarios',
      activeScenarioCollection: "programmatic/activeScenarioCollection"
    }),
    visibleModules() {
      //  return this.modules.filter((el) => { return this.activeArray.includes(el.id) })
      return this.modules
    },
    getModules() {
      if(this.scenarioCollections && this.scenarioCollections.length > 0) {
        if(this.isolatedModuleId) {
          return this.scenarioCollections.filter(data => data.id === this.isolatedModuleId);
        }
        return this.scenarioCollections;
      }
      return [];
    }
  },
  watch: {
    activeArr: function (val) {
      this.visibleModules();
    },
    moduleLevel: function (level) {
      let index = this.modules.findIndex(m => m.module === level);
      this.toggledParentSelection(index, true);
    },
    selectedModule: function (id) {
        this.scrollToLinkedScenarioModule(id)
    },
    scenarioCollections: function (val) {
      let newLength = val.length - this.zoomModules.length;
      if(newLength > 0) {
        for(let i = 0; i<=newLength; i++) {
          this.zoomModules.push({ viewerMode: 1 });
        }
      }
    },
  },
  methods: {
    setZoomModules() {
      this.zoomModules = [];
      for(let i = 0; i <= this.scenarioCollections.length; i++) {
        this.zoomModules.push({ viewerMode: 1 });
      }
    },
    activePropertyOption() {
      this.$emit("activePropertyOption");
    },
    getSelectionScenarioCollections() {
      let projectId = parseInt(this.$route.params.id);
      let scenarioId = parseInt(this.scenario_selected);
      this.$router.push({ name: 'programmatic.scenario.view', params: { id: projectId, scenario_id: scenarioId }});
      this.$store.dispatch("programmatic/loadScenarioCollection", {
        project_id: projectId,
        scenario_id: scenarioId
      });
    },
    changeLevel(item, index) {
      if(item.expandMode === 'inc') {
        item.expandedLevel += 1;
        if (item.expandedLevel === 4) {
          item.expandMode = 'dec';
        }
      } else {
        item.expandedLevel -= 1;
        if (item.expandedLevel === 0) {
          item.expandMode = 'inc';
        }
      }
    },
    toggleConnection(value) {
      this.selectedConnection = value;
      this.$emit("toggleConnection", value);
      if (value == 2) {
        this.loadAutoFilterRanges()
      }
      if (value == 1) {
        this.returnDefaultRanges()
      }
    },
    toggleCardView() {
      this.cardView = !this.cardView;
      this.activeCards = [];
      this.split = false;
      this.parentVisibility = false;

      if (this.cardView) {
        let selectedModuleData = this.modules[this.selectedParent].levelsData;
        if (selectedModuleData.length > 0) {
          let l1Data = selectedModuleData.filter(item => item.level === 1);
          if (l1Data.length > 0) {
            this.childItemSelection = l1Data[0].id;
          }
        }
      }
    },
    toggleChildItemSelection(id) {
      this.childItemSelection = id;
    },
    getIndexTitle(parentCodeIndex, index, moduleId) {
      let module = this.modules.find(item => parseInt(item.id) === parseInt(moduleId));
      if(module) {
        let moduleSettings = JSON.parse(module.collection_settings);
        if(moduleSettings && moduleSettings.collections) {
          if(moduleSettings.index_number) {
            return parseInt(moduleSettings.collections[parentCodeIndex].index_number);
          }
          return '';
        } else {
          return '';
        }
      }
      return '';
    },
    setDataSourceType(hasAssessment){
      this.$emit('setDataSourceType', hasAssessment)
    },
    async toggleAssessmentMenu(index, singleModule) {
      this.assessmentMenu = index
      this.showMenu = !this.showMenu

      if(this.showMenu){
        this.$set(singleModule, 'assessments', await this.getAssessment(singleModule.id))
      }
    },
    
    async changeAssessment(index, item, assessmentIndex)
    {
      this.showMenu = false

      let { assessments } = this.scenarioCollections[index]
      assessments.forEach(el => {
        el.selected = false
      })        
      assessments[assessmentIndex].selected = true

      let systemDataWithProperties = []
      if(assessmentIndex>0){ //in index 0 properties available
        systemDataWithProperties = await this.getSystemDataWithProperties(assessments[assessmentIndex].id)
      }

      // work with systemDataWithProperties start
        let flatArray = []
        function makeFlat(myArray){
          myArray.forEach(element => {
              element.module_id = item.id
              flatArray.push(element)
              if(element.children){
                  makeFlat(element.children)
                  delete element.children
              }
          })
        }
        makeFlat(systemDataWithProperties)
        // this.$set(item, 'assessmentProperties', flatArray)
        item.assessmentProperties = flatArray
        const { pushAssessmentProperties } = this.$refs[`module_${item.id}`][0]
        
        flatArray = assessmentIndex ? flatArray : []
        pushAssessmentProperties(flatArray)



      if(this.activeScenarioCollection.module_id == item.id){
        if(flatArray.length){ //if flatArray length > 0, than assessment selected
          this.setDataSourceType(true)
        }else{
          this.setDataSourceType(false)
        }
      }

      // work with systemDataWithProperties end

      // console.log(flatten(this.systemDataWithProperties.flat()))
      // console.log('assessment', assessments[assessmentIndex])
      // console.log(this.systemDataWithProperties)

      // this.assessments.forEach(el => {
      //   el.selected = false
      // });
      // index = 0; // remove soon
      // this.assessments[index].selected = true
      // this.showMenu = false
    },
    toggleAssessmentScreen() {
      this.$emit("toggleAssessmentScreen", true);
    },
    loadDefaultProperties() {
      const self = this;
      self.scenarioCollections.forEach((el, index) => {
        self.$store.dispatch("manageProperty/getPropertiesToModule", el.id)
        .then(response => {
          if(response && response.data && response.data.data) {
            let properties = response.data.data;
            let propertyChildSelected = null
            if(properties.length > 0) {
              let childProperties = properties[0].property_collections;

              if(childProperties.length > 0) {
                propertyChildSelected = childProperties[0].id;
              }
            }
            this.$store.dispatch("programmatic/getSelectedFilterProperties", {mIndex: index, property_id: propertyChildSelected})
          }
        });
      });

    },
    loadAutoFilterRanges() {
      this.scenarioCollections.forEach((element, mIndex) => {
        let ranges = [[0,10], [0,10], [0,10], [0,10]]
        let count = [0, 0,  0, 0]
        let sum = [0, 0,  0, 0]
        let avg = [0, 0,  0, 0]
        let scenarioCollectionData = element.scenario_collections
        scenarioCollectionData.forEach(el0 => {
          count[0]++
          let ch0 = el0.properties.find(x => x.property_collection_id == this.selectedFilterProperties[0])
          sum[0] += ch0 ? ch0.sample_value : 0;

          el0.child.forEach(el1 => {
            count[1]++
            let ch1 = el1.properties.find(x => x.property_collection_id == this.selectedFilterProperties[0])
            sum[1] += ch1 ? ch1.sample_value : 0;

            el1.child.forEach(el2 => {
            count[2]++
            let ch2 = el2.properties.find(x => x.property_collection_id == this.selectedFilterProperties[0])
            sum[2] += ch2 ? ch2.sample_value : 0;

              el2.child.forEach(el3 => {
                count[3]++
                let ch3 = el3.properties.find(x => x.property_collection_id == this.selectedFilterProperties[0])
                sum[3] += ch3 ? ch3.sample_value : 0;
              })

            })
          })
        })

        if (mIndex == 0) {
          for (let i = 0; i < 4; i++) {
            avg[i] = sum[i]/count[i]
            let range = this.getRange(avg[i])
            ranges[i] = [range[0], range[1]]
            if (i == 0) {
              this.$store.dispatch("programmatic/getFilterMinValues", {mIndex: mIndex, min: 0});
              this.$store.dispatch("programmatic/getFilterMaxValues", {mIndex: mIndex, max: 10});
              this.$store.dispatch("programmatic/getFilterMinValues", {mIndex: mIndex + 1, min: range[0]});
              this.$store.dispatch("programmatic/getFilterMaxValues", {mIndex: mIndex + 1, max: range[1]});
            }
          }
        }
        // else if (mIndex == 1) {
        //   let n1 = 0
        //   let total1 = 0
        //   let avrg1 = 0
        //   let prevScenarioCollectionData = this.scenarioCollections[0].scenario_collections.data
        //   prevScenarioCollectionData.forEach(el => {
        //     n1++;
        //     let ch1 = el.properties.find(x => x.property_collection_id == this.selectedFilterProperties[0])
        //     total1 += ch1 ? ch1.sample_value : 0;
        //   });
        //   avrg1 = total1/n1
        //   let range = this.getRange(avrg1)

        //   for (let i = 0; i < 4; i++) {
        //     ranges[i] = [range[0],range[1]]
        //     if (i == 0) {
        //       this.$store.dispatch("programmatic/getFilterMinValues", {mIndex: mIndex, min: range[0]});
        //       this.$store.dispatch("programmatic/getFilterMaxValues", {mIndex: mIndex, max: range[1]});
        //     }
        //   }
        // }
        // else {
          if(mIndex != 0) {
            let n = 0
            let total = 0
            let avrg = 0
            let prevScenarioCollectionData = this.scenarioCollections[mIndex-1].scenario_collections
            prevScenarioCollectionData.forEach(el => {
              n++;
              let ch = el.properties.find(x => x.property_collection_id == this.selectedFilterProperties[this.mIndex-1])
              if (ch && ch.sample_value > this.filterMinValues[this.mIndex] && ch.sample_value <= this.filterMaxValues[this.mIndex]) {
                n++;
                total += ch.sample_value
              }
            });
            avrg = total/n
            let range = this.getRange(avrg)

            for (let i = 0; i < 4; i++) {
              ranges[i] = [range[0], range[1]]
              if (i == 0 && this.scenarioCollections.length > mIndex + 1) {
                this.$store.dispatch("programmatic/getFilterMinValues", {mIndex: mIndex + 1, min: range[0]});
                this.$store.dispatch("programmatic/getFilterMaxValues", {mIndex: mIndex + 1, max: range[1]});
              }
            }
          }


        // }

      });
    },
    returnDefaultRanges() {
      this.scenarioCollections.forEach((el, mIndex) => {
        this.$store.dispatch("programmatic/getFilterMinValues", {mIndex: mIndex, min: 0});
        this.$store.dispatch("programmatic/getFilterMaxValues", {mIndex: mIndex, max: 10});
      })
    },
    getRange(val) {
      let rangeSet = [[0,2], [2, 4], [4, 6], [6, 8], [8, 10]]
      let range = rangeSet[0]
      if (val != 0) {
        for (let i = 0; i < rangeSet.length; i++) {
          const el = rangeSet[i];
          if (val > el[0] && val <= el[1]) {
            range[0] = el[0]
            range[1] = el[1]
          }
        }
      }
      return range
    },
    getTitle(index, item) {
      return item.title;
      // return this.getIndexTitle(0, index, item.id) + ' ' + item.title
    },
    scrollToLinkedScenarioModule(id) {
      let container = document.getElementById('prog-section-container')
      let selectedModule = document.getElementById('linked-module-'+id)
      if (selectedModule) {
        let position = selectedModule.offsetLeft
        container.scroll({
          left: position-20,
          behavior: 'smooth'
        });
        let module = this.scenarioCollections.find(item => parseInt(item.id) === parseInt(id));
        if(module) {
          module.expandedLevel = 4;
          module.expandMode = 'dec';
        }
      }
    },
    toggleFullScreen() {
      this.$emit("toggleFullScreen");
    },
    toggleZoom(id, index) {
      this.$refs[`module_${id}`][0].viewModifier();
    },
    zoomedFromChild(id, viewerMode) {
      let itemIndex = this.scenarioCollections.findIndex(mdl => parseInt(mdl.id) === parseInt(id));
      if(itemIndex >= 0) {
        this.zoomModules[itemIndex].viewerMode = viewerMode;
      }
    },
    updateForceKeys() {
      let exitingKeys = this.forceKeys[this.forceKeys.length - 1];
      if(exitingKeys === 9) {
        this.forceKeys = [10, 11, 12, 13, 14, 15, 16, 17, 18];
      } else {
        this.forceKeys = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      }
    },
    pushToIsolation(payload) {
      this.$emit("pushToIsolation", payload);
    }
  }
}
</script>

<style scoped>
.prog-right-part-fullscreen {
  width: 1840px;
  margin-left: -3px;
}
.slider {
  -webkit-appearance: none;
  height: 3px;
  background: #315467;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
  width: 480px;
}

.slider-black {
  background: #000000;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
}

input[type=range]::-webkit-slider-thumb:hover {
  color: #FFFFFF;
}

.slide-enter-active {
  transition: 1s;
}

.slide-enter {
  transform: translate(0%, -100%);
}

.slide-leave-active {
  transition: 0s;
}

.library-select {
  border: none;
}
.assessment-selection {
  top: 88px;
}
.assessment-menu {
  top: 90px;
  left: 40px;
  z-index: 999;
  min-width: 150px;
  white-space: nowrap;
  max-height: 287px;
}
.assessment-menu span {
  padding: 10px 12px;
}
.scenario-select {
  margin-left: 80px;
}
.prog-present-btn {
  border: none;
  outline: none;
  margin-left: 40px;
}
.prog-present-btn:focus {
  border: none;
  outline: none;
}
.zero-connection {
  width: 1210px;
}
.fake-div {
  height: 200px;
  min-width: 1400px;
}
.prog-default-left-opt {
  margin-left: 35px;
}
.prog-default-scen-box {
  padding: 4px;
}
</style>
